import { numberFormatter, relationshipManagersOutput, balanceOptsOutput, numOfYearsWithEmployerOutput, sectorOptsOutput, missedPaymentOptionsOutput, billsHistoryOptsOutput, dateToHumanFormat, creditConClass } from "components/misc/constants";

function ApplicationDetails({ loan }) {

    return (
        <div>
            <div className="px-0">
                <h3 className="text-lg font-semibold leading-7 text-gray-900 dark:text-white/[.8]">Scoring Information</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-white/[.5]">Denotes how we graded your credit-worthiness.</p>
            </div>
            <div className="mt-6 mb-10 border-t border-gray-100 dark:border-white/[.2]">
                <dl className="divide-y divide-gray-100 dark:divide-white/[.2]">
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Credit Score</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.score} %
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Debt to Income Ratio (DTI)</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.DIR} %
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Willingness conclusion</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            <span className={`px-3 py-1 rounded-md text-white ${creditConClass(loan?.willingness)}`}>
                                {loan?.willingness}
                            </span>
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Capacity conclusion</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            <span className={`px-3 py-1 rounded-md text-white ${creditConClass(loan?.capacity)}`}>
                                {loan?.capacity}
                            </span>
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Preliminary credit conclusion</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            <span className={`px-3 py-1 rounded-md text-white ${creditConClass(loan?.creditConc)}`}>
                                {loan?.creditConc}
                            </span>
                        </dd>
                    </div>
                </dl>
            </div>


            <div className="px-0">
                <h3 className="text-lg font-semibold leading-7 text-gray-900 dark:text-white/[.8]">Loan Information</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Loan details and application.</p>
            </div>
            <div className="mt-6 mb-6 border-t border-gray-100 dark:border-white/[.2]">
                <dl className="divide-y divide-gray-100 dark:divide-white/[.2]">
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Relationship Manager</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {relationshipManagersOutput[loan?.relManager]}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Amount Requested</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            GHC {numberFormatter(loan?.amountReq)}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Loan Amount</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            GHC {numberFormatter(loan?.amount)}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Purpose of loan</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.purpose}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Desired payment plan</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.paymentPlan}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Loan duration (Term in months)</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.paymentDuration}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Repayment Method</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0 capitalize">
                            {loan?.repaySource}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Currently Employed</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.employedCurrently}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Receive regular monthly income</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.incomeStatus}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Monthly income equal or greater than GHC2,000</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.salaryStatus}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Monthly income</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            GHC {numberFormatter(loan?.income)}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Monthly debt</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            GHC {numberFormatter(loan?.debtAmt)}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Savings account balance</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {balanceOptsOutput[loan?.momoBalance]}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Name of employer</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.employerName}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Number of years at work</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {numOfYearsWithEmployerOutput[loan?.yearsAtWork]}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Sector of employment</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {sectorOptsOutput[loan?.employmentSector]}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Outstanding debts or loans</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.outstandingLoans}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Taken a loan from any financial institution</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.loansTaken}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Ever defaulted or missed a loan payment</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.loanDefaultStatus}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Number of times you missed payment on your loan</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {missedPaymentOptionsOutput[loan?.defaultedLoanStatus]}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Bills that you are responsible for</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.billsOption}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Length of history of your bills payment history</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {billsHistoryOptsOutput[loan?.billsHistory]}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Number of times you missed payment on your bills</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {missedPaymentOptionsOutput[loan?.billsHistoryStatus]}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Recent months loan payment history</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.loanPaymentHistory}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Recent months bills payment history</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.billPaymentHistory}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Savings Habit</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.savingHabits}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Insurance Habit</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.typeOfInsurance !== "None" ? `Yes / ${loan?.typeOfInsurance}` : "No"}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Loan payment automated / Method of automation</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.automatedPaymentsStatus} / {loan?.autoDeduction}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">
                            Guarantor's name
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.guarantorFirstName} {loan?.guarantorLastName}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">
                            Guarantor's Phone Number
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.guarantorPhone}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">
                            Guarantor's Address
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.guarantorAddress}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">
                            Guarantor's Relationship to you
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.guarantorRelationship}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">
                            Guarantor's ID Type and Number
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.guarantorIdType} / <span className="font-medium">{loan?.guarantorIdNumber}</span>
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">
                            Guarantor's digital address
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {loan?.guarantorDigitalAddress}
                        </dd>
                    </div>
                    <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">
                            Guarantor's date of birth
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-white/[.7] sm:col-span-2 sm:mt-0">
                            {dateToHumanFormat(loan?.guarantorDob)}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}

export default ApplicationDetails