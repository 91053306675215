import { roundNum } from "../constants";

//  ---- SCORING CARDS AND WEIGHTS -----  //
// 1. working capital score (cash available in savings account)
const capitalMeIncomeRange = {
    above15k : 25,
    max15k : 20,
    max10k : 15,
    max5k : 10,
    below3k : 5
}
const workingCapitalMax = Math.max(...Object.values(capitalMeIncomeRange));
const workingCapitalMaxWght = 10;
function getWorkingCapScore(workingCap) {
    if (!Number.isFinite(workingCap)) throw new Error(`Illegal cash available in savings account input`);
    let mnwScore = (workingCap / workingCapitalMax ) * workingCapitalMaxWght;
    return {val: roundNum(mnwScore, 2), unweighted: workingCap};
}

// 2. get normalized monthly net worth score
const momoAverage = {
    above8k : 25,
    max8k : 20,
    max6k : 15,
    max4k : 10,
    below2k : 5
}
const averageCashFlowMax = Math.max(...Object.values(momoAverage));
const averageCashFlowMaxWght = 15;
function getMonthlyIncomeScore(monthNetWorth) {
    if (!Number.isFinite(monthNetWorth)) throw new Error(`Illegal momo payment input`);
    let mnwScore = (monthNetWorth / averageCashFlowMax) * averageCashFlowMaxWght;
    return {val: roundNum(mnwScore, 2), unweighted: monthNetWorth};
}

// 3. new scoring dti figures
const scoringDTIObj = {
    below35 : 30,
    upTo43 : 20,
    upTo50 : 15,
    above51 : 5
}
const scoringDTIDenom = Math.max(...Object.values(scoringDTIObj));
const scoringDTIMaxWgt = 12;
function getDebtToIncomeScore(scoringDTI){
    let raw = scoringDTI <= 35 ? scoringDTIObj.below35 : scoringDTI >= 36 && scoringDTI <= 43 ? scoringDTIObj.upTo43 : scoringDTI >= 44 && scoringDTI <= 50 ? scoringDTIObj.upTo50 : scoringDTIObj.above51;
    if(!Number.isFinite(raw)) throw new Error('Illegal scoring dti');
    let score = (raw / scoringDTIDenom) * scoringDTIMaxWgt;
    return { val: roundNum(score, 2), unweighted : raw };
}

// 4. Transaction Frequency in a month
const transFreqObj = {
    freq : 30,
    regular : 20,
    rarely : 0
}
const transFreqMax = Math.max(...Object.values(transFreqObj));
const transFreqMaxWgt = 7;
function getTransactionFrequencyScore(num){
    let raw = transFreqObj[num];
    if (!Number.isFinite(raw)) throw new Error(`Illegal transaction frequency in a month`);
    let score = (raw / transFreqMax) * transFreqMaxWgt;
    return {val: roundNum(score, 2), unweighted: raw };
}

// 5. Consistency of Transactions
const consistTransObj = {
    consist : 30,
    moderate : 20,
    somewhat : 10,
    volatile : 0
}
const consistTransMax = Math.max(...Object.values(consistTransObj));
const consistTransMaxWgt = 7;
function getTransactionConsistencyScore(num){
    let raw = consistTransObj[num];
    if (!Number.isFinite(raw)) throw new Error(`Illegal consistency of transactions`);
    let score = (raw / consistTransMax) * consistTransMaxWgt;
    return {val: roundNum(score, 2), unweighted: raw};
}

// 6. Recent 3 months loan payment history
const loanPaymentHstObj = {
    Yes : 30,
    No : 0
}
const loanPaymentHstDenom = Math.max(...Object.values(loanPaymentHstObj));
const loanPaymentHstMaxWgt = 10;
function getLoanPaymentHistoryScore(relTime){
    let raw = loanPaymentHstObj[relTime];
    if (!Number.isFinite(raw)) throw new Error(`Illegal recent 3 months loan payment history input`);
    let mnwScore = (raw / loanPaymentHstDenom) * loanPaymentHstMaxWgt;
    return {val: roundNum(mnwScore, 2), unweighted: raw};
}

// 7. Credit status for recent 3 months loan payment history
const creditStatusLoanBills = {
    noMiss : 30,
    max1 : 20,
    max2 : 5,
    missedMore : 0,
    noCred : 0
}
const creditStatusLoanBillsDenom = Math.max(...Object.values(creditStatusLoanBills));
const creditStatusLoanPaymentMaxWgt = 7;
function getCreditStatusLoanScore(lvlEvidence){
    let raw = creditStatusLoanBills[lvlEvidence];
    if (!Number.isFinite(raw)) throw new Error('Illegal credit status for recent 3 months loan payment history input');
    let score = (raw / creditStatusLoanBillsDenom) * creditStatusLoanPaymentMaxWgt;
    return {val: roundNum(score, 2), unweighted: raw};
}

// 8. Recent 3 months bills payment history
const billsPaymentHstObj = {
    Yes : 30,
    No : 0
}
const billsPaymentHstDenom = Math.max(...Object.values(billsPaymentHstObj));
const billsPaymentHstMaxWgt = 5;
function getBillsPaymentHistoryScore(billsHistory){
    let raw = billsPaymentHstObj[billsHistory];
    if (!Number.isFinite(raw)) throw new Error('Illegal recent 3 months bills payment history input');
    let score = (raw / billsPaymentHstDenom) * billsPaymentHstMaxWgt;
    return {val: roundNum(score, 2), unweighted: raw};
}

// 9. Credit status for recent 3 months bills payment history
const creditStatusBillsPaymentMaxWgt = 5;
function getCreditStatusBillsScore(billsHistoryStatus){
    let raw = creditStatusLoanBills[billsHistoryStatus];
    if (!Number.isFinite(raw)) throw new Error('Illegal bills history status');
    let score = (raw / creditStatusLoanBillsDenom) * creditStatusBillsPaymentMaxWgt;
    return {val: roundNum(score, 2), unweighted: raw};
}

// 10. Years operating this business in this location
const businessYears = {
    above3 : 30,
    max2 : 20,
    max1 : 0
}
const yearsInOperationDenom = Math.max(...Object.values(businessYears));
const maxYearsInOperation = 7;
function getYearsInOperationsScore(yearsInOperationInput) {
    let yearsInOperationScore = (businessYears[yearsInOperationInput] / yearsInOperationDenom) * maxYearsInOperation;
    return {val: roundNum(yearsInOperationScore, 2), unweighted: yearsInOperationInput};
}

// 11. Savings habit score
const savingsFreqMaxWgt = 5;

// 12. automated payments scoring inputs
const automatedPaymentsObj = {
    Yes : 30,
    No : 0
}
const automatedPaymentsDenom = Math.max(...Object.values(automatedPaymentsObj));
const automatedPaymentsMaxWgt = 10;

// customer score
function getCustomerScore(customerInputs) {
    // 1. Cash available in savings account
    let workingCapScore = getWorkingCapScore(customerInputs.workingCapital);

    // 2. Income
    let monthlyIncomeScore = getMonthlyIncomeScore(customerInputs.cashFlow);

    // 3. Debt to income ratio
    let debtToIncomeScore = getDebtToIncomeScore(customerInputs.scoringDTI);

    // 4. Transaction frequency in a month
    let transactionFrequencyScore = getTransactionFrequencyScore(customerInputs.transFreq);

    // 5. Consistency of transaction
    let transactionConsistencyScore = getTransactionConsistencyScore(customerInputs.consistTrans);

    // 6. Recent 3 months loan payment history
    let loanPaymentHistoryScore = getLoanPaymentHistoryScore(customerInputs.loanPaymentHistory);

    // 7. Credit status for loan payment history
    let creditStatusLoanScore = getCreditStatusLoanScore(customerInputs.defaultedLoanStatus);

    // 8. Recent 3 months bills payment history
    let billsPaymentHistoryScore = getBillsPaymentHistoryScore(customerInputs.billPaymentHistory);

    // 9. Credit status for bills payment history
    let creditStatusBillsScore = getCreditStatusBillsScore(customerInputs.billsHistoryStatus);

    console.log("Cash available in savings account: ", workingCapScore.val);
    console.log("Income: ", monthlyIncomeScore.val);
    console.log("Scoring DTI: ", debtToIncomeScore.val);
    console.log("Transaction Frequency in a month: ", transactionFrequencyScore.val);
    console.log("Consistency of Transaction: ", transactionConsistencyScore.val);
    console.log("Recent 3 months loan payment history: ", loanPaymentHistoryScore.val);
    console.log("Credit status of loan payment history: ", creditStatusLoanScore.val);
    console.log("Recent 3 months bills payment history: ", billsPaymentHistoryScore.val);
    console.log("Credit status of bills payment history: ", creditStatusBillsScore.val);

    // total score
    let score = workingCapScore.val + monthlyIncomeScore.val + debtToIncomeScore.val + transactionFrequencyScore.val + transactionConsistencyScore.val + loanPaymentHistoryScore.val + creditStatusLoanScore.val + billsPaymentHistoryScore.val + creditStatusBillsScore.val;
    console.log("Total score: ", score);

    return roundNum(score,2);
}

// company score
function getCompanyScore(companyInputs) {
    // 10. Years operating this business in this location
    let yearsInOperationScore = getYearsInOperationsScore(companyInputs.yearsInOperation);
    let score = yearsInOperationScore.val;
    
    return roundNum(score,2)
}

// industry score
function getIndustryScore(industryInputs) {

    // 11. Savings habit score
    let savingsScore = industryInputs.savingHabits === "Yes" ? savingsFreqMaxWgt : 0;

    // 12. automated payments scoring inputs
    let automatedPaymentsRaw = automatedPaymentsObj[industryInputs.automatedPaymentsStatus];
    let automatedScore = (automatedPaymentsRaw / automatedPaymentsDenom) * automatedPaymentsMaxWgt;
    automatedScore = roundNum(automatedScore, 2);

    console.log("Savings habit score: ", savingsScore);
    console.log("Automated payments score: ", automatedScore);

    // total score
    let score = savingsScore + automatedScore;
    console.log("Total score: ", score);

    return roundNum(score,2);

}

async function getCapitalMeScore(customerInputs, companyInputs, industryInputs) {

    // get the customer score
    let customerScore = getCustomerScore(customerInputs);

    // get the company score
    let companyScore = getCompanyScore(companyInputs);

    // get the industry score
    let industryScore = getIndustryScore(industryInputs);

    console.log(`customer score: ${customerScore}, company score: ${companyScore}, industry score: ${industryScore}`);

    // get the capital me score (total score)
    let totalScore = customerScore + companyScore + industryScore;
    console.log(`total score: ${totalScore}`);

    return roundNum(totalScore,2);
}

export {
    getCapitalMeScore
}