import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components'

function LoanRequirement({ isOpen, setIsLoanReqOpen, type, requirements }) {

    function closeModal() {
        setIsLoanReqOpen(false)
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-md md:max-w-2xl transform overflow-hidden rounded-xl dark:bg-dark-bg bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white/[.7]">
                                {type} Requirements
                            </Dialog.Title>
                            <div className="mt-3">
                                <p className="text-sm text-gray-500 dark:text-white/[.7] p-3 bg-blue-50 dark:bg-dark-page rounded-md">
                                    The following are the required documents for this loan and they are mandatory. Please make sure you have all of them before proceeding.
                                </p>

                                {requirements?.map((requirement, index) => (
                                    <div key={index} className="flex items-center mt-4">
                                        <div className="flex-shrink-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 text-green-400">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-sm text-gray-900 dark:text-white/[.7]">{requirement}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-8">
                                <Button 
                                    btnType={"button"}
                                    btnText={"Got it, thanks!"}
                                    btnClasses={"form-button-default"}
                                    btnFunction={closeModal}
                                />
                            </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default LoanRequirement;