import { getRequest } from 'https/requests';
import { useState, useCallback, useEffect } from 'react';
import { useUser } from 'hooks';
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
import { useNavigate } from 'react-router-dom';

function useGetUser() {

    const { accessToken, idToken } = useUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchProfile = useCallback(async() => {
        setLoading(true);
        let url = `${process.env.REACT_APP_STAGING_AUTH}/profile`;

        try {

            let res = await getRequest(url, accessToken, idToken, {});

            if(res?.code === 401){
                navigate("/login");
                dispatch(show({
                    state : true,
                    message : `Your session has expired, please login again.`,
                    type : "token"
                }))
                return;
            }

            if(res?.err){
                dispatch(show({
                    state : true,
                    message : `${res?.err}`,
                    type : "error"
                }))
                return;
            }

            const { password, ...rest } = res;

            setProfile(rest)
            
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    }, [dispatch, navigate, accessToken, idToken])

    useEffect(() => {
        fetchProfile();
    }, [fetchProfile, accessToken, idToken])

    return { loading, profile }
}

export default useGetUser