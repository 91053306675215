import { roundNum } from "../constants";
import { workYears } from "../loanConstants";

// dti score
const scoringDTIStatus = {
    below35 : 30,
    upTo43 : 20,
    upTo50 : 15,
    above51 : 5
}
const scoringDTIDenom = Math.max(...Object.values(scoringDTIStatus));
const scoringDTIMaxWgt = 9;

// income score
const loanMeIncomeScores = {
    above10k : 40,
    max10k : 30,
    max5k : 20,
    below2k : 10
}
const incomeFlowMax = Math.max(...Object.values(loanMeIncomeScores));
const incomeFlowMaxWgt = 10;

// investment size score
const investSizeObj = {
    above10 : 40,
    max5 : 30,
    below5 : 20,
}
const investSizeMax = Math.max(...Object.values(investSizeObj));
const investSizeMaxWgt = 9;

// investment freq score
const investFreqObj = {
    leastOnce : 40,
    max3 : 30,
    max1 : 20,
}
const investFreqMax = Math.max(...Object.values(investFreqObj));
const investFreqMaxWgt = 5;

// savings percentage score
const savingsPercentObj = {
    above20 : 40,
    max20 : 30,
    less10 : 20,
}
const savingsPercMax = Math.max(...Object.values(savingsPercentObj));
const savingsPercMaxWgt = 5;

// savings frequency score
const savingsFreqObj = {
    always : 40,
    occasional : 30,
    irregular : 20,
}
const savingsFreqMax = Math.max(...Object.values(savingsFreqObj));
const savingsFreqMaxWgt = 5;

// transaction frequency score
const transactionFreqObj = {
    everyday : 40,
    twiceWeek : 30,
    onceWeek : 20,
}
const transactionFreqMax = Math.max(...Object.values(transactionFreqObj));
const transactionFreqMaxWgt = 5;

// transaction size score
const transactionSizeObj = {
    above1k : 40,
    max1k : 30,
    below500 : 20,
}
const transactionSizeMax = Math.max(...Object.values(transactionSizeObj));
const transactionSizeMaxWgt = 5;

// account age score
const accountAgeObj = {
    above5yr : 40,
    max4yr : 30,
    max2yr :20,
    less1yr : 10
}
const accountAgeMax = Math.max(...Object.values(accountAgeObj));
const accountAgeMaxWgt = 5;

// activity frequency score
const activeFreqObj = {
    everyday : 40,
    twiceWeek : 30,
    onceWeek : 20,
    noneWeek : 10
}
const activeFreqMax = Math.max(...Object.values(activeFreqObj));
const activeFreqMaxWgt = 5;

// average account balance score
const accountBalAvgObj = {
    above5Avg : 40,
    max5Avg : 30,
    max1Avg : 20,
    less1Avg : 10,
}
const accountBalAvgMax = Math.max(...Object.values(accountBalAvgObj));
const accountBalAvgMaxWgt = 5;

// years with current employer
const workYearsDenom = Math.max(...Object.values(workYears))
const workYearsMaxWgt = 5;

// automated payments scoring
const automatedPaymentsObj = {
    Yes : 30,
    No : 0
}
const automatedDenom = Math.max(...Object.values(automatedPaymentsObj));
const automatedPaymentsMaxWgt = 5;

// recent 3 months loan and bills payment history
const loanPaymentHstObj = {
    Yes : 30,
    No : 0
}
const loanPaymentHstMax = Math.max(...Object.values(loanPaymentHstObj));
const loanPaymentHstMaxWgt = 7;
const billPaymentHstMaxWgt = 5;

// credit status of applicable borrowing or payment history
const creditStatusAppHst = {
    noMiss : 30,
    max1 : 20,
    max2 : 5,
    missedMore : 0,
    noCred : 0
}
const creditStatusAppHstMax = Math.max(...Object.values(creditStatusAppHst));
const creditStatusAppHstMaxWght = 5;
const creditStatusbillPaymentMaxWght = 5;


async function getInvestmentScore(scoringDTI, averageCashFlow, investSize, investFreq, savingsPerc, savingsFreq, transactionFreq, transactionSize, accountAge, activeFreq, accountBalAvg, yearsAtWork, automatedPaymentsStatus, loanPaymentHst, paymentHstWCedisPay, billsPaymentHst, billsHistoryStatus){

    // 1. scoring DTI
    let scoringDTIRaw = scoringDTI <= 35 ? scoringDTIStatus.below35 : 
                        scoringDTI >= 36 && scoringDTI <= 43 ? scoringDTIStatus.upTo43 : 
                        scoringDTI >= 44 && scoringDTI <= 50 ? scoringDTIStatus.upTo50 : 
                        scoringDTIStatus.above51;
    let scoringDTIScore = (scoringDTIRaw / scoringDTIDenom) * scoringDTIMaxWgt;
    scoringDTIScore = roundNum(scoringDTIScore, 2);
    console.log("Debt to income ratio: ", scoringDTIScore);

    // 2. income 
    let incomeScore = (averageCashFlow / incomeFlowMax) * incomeFlowMaxWgt;
    incomeScore = roundNum(incomeScore, 2);
    console.log("Income:", incomeScore);

    // 3. investment size
    let investSizeRaw = investSizeObj[investSize];
    let investSizeScore = (investSizeRaw / investSizeMax) * investSizeMaxWgt;
    investSizeScore = roundNum(investSizeScore, 2);
    console.log("investment size:", investSizeScore);

    // 4. investment frequency
    let investFreqRaw = investFreqObj[investFreq];
    let investFreqScore = (investFreqRaw / investFreqMax) * investFreqMaxWgt;
    investFreqScore = roundNum(investFreqScore, 2);
    console.log("investment frequency:", investFreqScore);

    // 5. savings percentage
    let savingsPercRaw = savingsPercentObj[savingsPerc];
    let savingsPercScore = (savingsPercRaw / savingsPercMax) * savingsPercMaxWgt;
    savingsPercScore = roundNum(savingsPercScore, 2);
    console.log("savings percentage:", savingsPercScore);

    // 6. savings frequency
    let savingsFreqRaw = savingsFreqObj[savingsFreq];
    let savingsFreqScore = (savingsFreqRaw / savingsFreqMax) * savingsFreqMaxWgt;
    savingsFreqScore = roundNum(savingsFreqScore, 2);
    console.log("savings frequency:", savingsFreqScore);

    // 7. transaction frequency
    let transactionFreqRaw = transactionFreqObj[transactionFreq];
    let transactionFreqScore = (transactionFreqRaw / transactionFreqMax) * transactionFreqMaxWgt;
    transactionFreqScore = roundNum(transactionFreqScore, 2);
    console.log("transaction frequency:", transactionFreqScore);

    // 8. transaction size
    let transactionSizeRaw = transactionSizeObj[transactionSize];
    let transactionSizeScore = (transactionSizeRaw / transactionSizeMax) * transactionSizeMaxWgt;
    transactionSizeScore = roundNum(transactionSizeScore, 2);
    console.log("transaction size:", transactionSizeScore);

    // 9. account age
    let accountAgeRaw = accountAgeObj[accountAge];
    let accountAgeScore = (accountAgeRaw / accountAgeMax) * accountAgeMaxWgt;
    accountAgeScore = roundNum(accountAgeScore, 2);
    console.log("account age:", accountAgeScore);

    // 10. activity frequency
    let activeFreqRaw = activeFreqObj[activeFreq];
    let activeFreqScore = (activeFreqRaw / activeFreqMax) * activeFreqMaxWgt;
    activeFreqScore = roundNum(activeFreqScore, 2);
    console.log("activity frequency:", activeFreqScore);

    // 11. average account balance
    let accountBalAvgRaw = accountBalAvgObj[accountBalAvg];
    let accountBalAvgScore = (accountBalAvgRaw / accountBalAvgMax) * accountBalAvgMaxWgt;
    accountBalAvgScore = roundNum(accountBalAvgScore, 2);
    console.log("average account balance:", accountBalAvgScore);

    // 12. years with current employer
    let yearsAtWorkRaw = yearsAtWork;
    let yearsAtWorkScore = (yearsAtWorkRaw/workYearsDenom) * workYearsMaxWgt;
    yearsAtWorkScore = roundNum(yearsAtWorkScore, 2);
    console.log("Years at work:", yearsAtWorkScore);

    // 13. automated payments 
    let automatedRaw = automatedPaymentsObj[automatedPaymentsStatus];
    let automatedScore = (automatedRaw / automatedDenom) * automatedPaymentsMaxWgt;
    automatedScore = roundNum(automatedScore, 2);
    console.log("Automated payments: ", automatedScore);

    // 14. recent 3 months loan payment history
    let loanPaymentHstRaw = loanPaymentHstObj[loanPaymentHst];
    let loanPaymentHstScore = (loanPaymentHstRaw / loanPaymentHstMax) * loanPaymentHstMaxWgt;
    loanPaymentHstScore = roundNum(loanPaymentHstScore, 2);
    console.log("Recent 3 months loan payment history:", loanPaymentHstScore);

    // 15. credit status of applicable loan payment history
    let creditStatusloanPaymentRaw = creditStatusAppHst[paymentHstWCedisPay];
    let creditStatusloanPaymentScore = (creditStatusloanPaymentRaw / creditStatusAppHstMax) * creditStatusAppHstMaxWght;
    creditStatusloanPaymentScore = roundNum(creditStatusloanPaymentScore, 2);
    console.log("Credit Status of applicable loan payment history:", creditStatusloanPaymentScore);
    
    // 16. 3 months bills payment history
    let billsPaymentHstRaw = loanPaymentHstObj[billsPaymentHst];
    let billsPaymentHstScore = (billsPaymentHstRaw / loanPaymentHstMax) * billPaymentHstMaxWgt;
    billsPaymentHstScore = roundNum(billsPaymentHstScore, 2);
    console.log("Recent 3 months bills payment history:", billsPaymentHstScore);

    // 17. credit status of applicable bills payment history
    let creditStatusbillPaymentRaw = creditStatusAppHst[billsHistoryStatus];
    let creditStatusbillPaymentScore = (creditStatusbillPaymentRaw / creditStatusAppHstMax) * creditStatusbillPaymentMaxWght;
    creditStatusbillPaymentScore = roundNum(creditStatusbillPaymentScore, 2);
    console.log("Credit Status of applicable bills payment history:", creditStatusbillPaymentScore);

    let totalScore = scoringDTIScore + incomeScore + investSizeScore + investFreqScore + savingsPercScore + savingsFreqScore + transactionFreqScore + transactionSizeScore + accountAgeScore + activeFreqScore + accountBalAvgScore + yearsAtWorkScore + automatedScore + loanPaymentHstScore + creditStatusloanPaymentScore + billsPaymentHstScore + creditStatusbillPaymentScore;

    totalScore = roundNum(totalScore, 1);

    return {
        score: totalScore,
    }

}

export {
    getInvestmentScore
}