import { roundNum } from '../constants';

// weights and max constants
const operationWgt = 5;
const workingCapitalMaxWght = 5;
const averageCashFlowMaxWght = 15;

// defining constants
const custRelTimeValue = { above4 : 30, max4 : 27, max2 : 25, less3 : 10, noHist : 10 }
const momoBalance = { above3k : 25, max3k : 20, max2k : 15, max1k : 10, below500 : 5 }
const momotransactions = { more20 : 30, more15 : 20, more5 : 10, more1 : 5, zeroTrans : 0 }
const momoAverageValue = { above8k : 25, max8k : 20, max6k : 15, max4k : 10, below2k : 5 }

// cashme score constants
// bills history payment and credit status
const billsPaymentHistory = { above4 : 30, max4 : 27, max2 : 25, less3 : 10, noHist : 10 }
const billsPaymentHistoryMax = Math.max(...Object.values(billsPaymentHistory));
const billsPaymentHistoryWgt = 10;

const transEvidences = { noMiss : 30, max1 : 20, max2 : 5, missedMore : 0, noCred : 0 }
const lvlEvidenceMaxWght = 10;
const lvlEvidenceMax = Math.max(...Object.values(transEvidences));

const numTransactions = { Yes : 30, No : 15 }
const numTransactionsMax = Math.max(...Object.values(numTransactions));
const numOfTransactionsMaxWgt = 5;

const workingCapitalMax = Math.max(...Object.values(momoBalance));

const averageCashFlowMax = Math.max(...Object.values(momoAverageValue));

const momoTransactionMax = Math.max(...Object.values(momotransactions));
const momoTransactionMaxWght = 10;

const custRelMaxWght = 10;
const custRelTimeMax = Math.max(...Object.values(custRelTimeValue));

const businessYears = { above3 : 30, max2 : 20, max1 : 0 }
const yearsInOperationDenom = Math.max(...Object.values(businessYears));




async function getCashMeScore(workingCapital, averageCashFlow, momo_transactions, numOfTransctions, paymentHistory, paymentHistoryStatus, billsHistory, billsHistoryStatus, yearsWithEmployer, savingHabits, insuranceHabits){
    // working capital values (cash available in savings account)
    let workingCapRaw = workingCapital;
    let workingCapScore = (workingCapRaw / workingCapitalMax) * workingCapitalMaxWght;
    workingCapScore = roundNum(workingCapScore, 2);

    // momo average (monthly revenue / income)
    let momoRaw = averageCashFlow;
    let mnwScore = (momoRaw/ averageCashFlowMax) * averageCashFlowMaxWght;
    mnwScore = roundNum(mnwScore, 2);

    // check number of transactions (average number of transactions per month)
    let numTransRaw = parseFloat(momotransactions[momo_transactions]);
    let numTransScore = (numTransRaw / momoTransactionMax) * momoTransactionMaxWght;
    numTransScore = roundNum(numTransScore, 2);
    
    // status of number of transactions (at least 20 transactions)
    let transactionsRaw = numTransactions[numOfTransctions]
    let transactionsScore = (transactionsRaw / numTransactionsMax) * numOfTransactionsMaxWgt;
    transactionsScore = roundNum(transactionsScore, 2);

    // check length of history of applicable borrowing
    let custRelTimeScore = (custRelTimeValue[paymentHistory] / custRelTimeMax) * custRelMaxWght;
    custRelTimeScore = roundNum(custRelTimeScore, 2);

    // payment history credit status
    let lvlRaw = transEvidences[paymentHistoryStatus];
    let lvlEvidenceScore = (lvlRaw / lvlEvidenceMax) * lvlEvidenceMaxWght;
    lvlEvidenceScore = roundNum(lvlEvidenceScore, 2);

    // bills history
    let billsHistoryRaw = billsPaymentHistory[billsHistory];
    let billsHistoryScore = (billsHistoryRaw / billsPaymentHistoryMax) * billsPaymentHistoryWgt;
    billsHistoryScore = roundNum(billsHistoryScore, 2);

    // bills history credit status
    let billsHistoryStatusRaw = transEvidences[billsHistoryStatus];
    let billsHistoryStatusScore = (billsHistoryStatusRaw / lvlEvidenceMax) * lvlEvidenceMaxWght;
    billsHistoryStatusScore = roundNum(billsHistoryStatusScore, 2);

    //  totalScore
    let totalScore = workingCapScore + mnwScore + numTransScore + transactionsScore + custRelTimeScore + lvlEvidenceScore + billsHistoryScore + billsHistoryStatusScore;

    totalScore = roundNum(totalScore, 1);

    // check years with employer
    let yearScore = (yearsWithEmployer / yearsInOperationDenom) * operationWgt;
    yearScore = roundNum(yearScore, 2);

    // habit scores
    // savings habit
    let defSav = savingHabits === "Yes" ? 15 : 0;
    let savingsHabitsScore = defSav;

    // insurance habit
    let defIns = insuranceHabits === "Yes" ? 5 : 0;
    let insuranceHabitsScore = defIns;

    let totalHabits = savingsHabitsScore + insuranceHabitsScore;

    let summary = totalScore + yearScore + totalHabits;

    console.log(`Scores: WorkingCapital: ${workingCapScore}, Income: ${mnwScore}, Average number of transactions: ${numTransScore}, Number of transactions per month: ${transactionsScore}, Payment History: ${custRelTimeScore}, Payment History Credit Status: ${lvlEvidenceScore}, Bills History: ${billsHistoryScore}, Bills History Credit Status: ${billsHistoryStatusScore}, Years operating business in location: ${yearScore}, Savings Habit: ${savingsHabitsScore}, Insurance Habit: ${insuranceHabitsScore}`);

    return {
        score : summary
    }
}

export {
    getCashMeScore
}