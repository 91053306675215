import React from 'react'
import { useLocation } from "react-router-dom";
import { Aside, Main, PageHeader, Topbar, LoanMe, Pension, Pencash, CapitalMe, Credit, CashMe, Investment, Payroll, Salaried } from 'components'
import { useUser } from 'hooks';

function Switch() {

    const { user } = useUser();
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const currentPath = path[2];

    const switchLoans = () => {
        switch (currentPath) {
            // case "loanme":
            //     return <LoanMe />;
            case "pension":
                return <Pension client={user} />;
            case "pencash":
                return <Pencash client={user} />;
            case "capitalme":
                return <CapitalMe client={user} />;
            case "cediscredit":
                return <Credit client={user} />;
            case "cashme":
                return <CashMe client={user} />;
            case "investment":
                return <Investment client={user} />;
            case "payroll":
                return <Payroll client={user} />;
            case "salaried":
                return <Salaried client={user} />;
            default:
                return <LoanMe client={user} />;
        }
    }

    return (
        <main>
            <Topbar />
            
            <div className='pt-16 w-full flex overflow-hidden bg-light-bg dark:bg-dark-page'>
                <Aside />

                <Main>
                    <PageHeader 
                        title={`${currentPath[0].toUpperCase() + currentPath.substring(1)} Application`} 
                        size="text-2xl dark:text-slate-200"   
                    />

                    {switchLoans(currentPath)}
                </Main>
            </div>
        </main>
    )
}

export default Switch