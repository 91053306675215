import { Link } from 'react-router-dom'

function Footer() {

    const year = new Date().getFullYear();

    return (
        <>
            <footer className='display_card flex items-center justify-between'>
                <ul className='grid grid-cols-2 w-full md:flex md:flex-row md:items-center md:space-x-6 md:space-y-0'>
                    <li className='w-full py-4 md:py-2 group'>
                        <Link to="/terms" className='group-hover:text-gold-500'>
                            Terms &amp; Conditions
                        </Link>
                    </li>
                    <li className='w-full py-4 md:py-2 group'>
                        <Link to="/privacy-policy" className='group-hover:text-gold-500'>
                            Privacy Policy
                        </Link>
                    </li>
                    <li className='w-full py-4 md:py-2 group'>
                        <Link to="/cookie-policy" className='group-hover:text-gold-500'>
                            Cookie Policy
                        </Link>
                    </li>
                    <li className='w-full py-4 md:py-2 group'>
                        <Link to="/contact" className='group-hover:text-gold-500'>
                            Contact
                        </Link>
                    </li>
                </ul>

                <div>
                    {/* <Link to="/">

                    </Link> */}
                </div>
            </footer>

            <p className='text-center my-10 mx-10 md:mx-0'>
                &copy; 2019 - {year} CedisPay Micro-Finance Limited. All Rights Reserved.
            </p>
        </>
    )
}

export default Footer