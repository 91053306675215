import { useEffect, useState } from 'react'
import useUser from './useUser';
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
import { fetchRequests } from 'https/requests';
import { useNavigate } from 'react-router-dom';

function useBusinesses() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [businesses, setBusinesses] = useState([]);
    const { accessToken } = useUser();

    // get-businesses

    useEffect(() => {

        const fetchBusinesses = async() => {
            setIsLoading(true);

            const url = `${process.env.REACT_APP_STAGING_LOAN}/get-businesses`;

            const headers = {
                'Content-Type' : 'application/json',
                "authorization" : `${accessToken}`,
            }

            try {

                let res = await fetchRequests(url, "get", headers, {}, {});

                if(res?.code === 401){
                    navigate("/login");
                    dispatch(show({
                        state : true,
                        message : `${res?.err}, Please login again`,
                        type : "error"
                    }))
                    return;
                }

                if(res?.err){
                    dispatch(show({
                        state : true,
                        message : `${res?.err}`,
                        type : "token"
                    }))
                    return;
                }

                setBusinesses(res)
                
            } catch (error) {
                dispatch(show({
                    state : true,
                    message : `${error}`,
                    type : "token"
                }))
            } finally {
                setIsLoading(false);
            }
        }

        fetchBusinesses();

    }, [dispatch, navigate, accessToken])

    return { isLoading, businesses }

}

export default useBusinesses;