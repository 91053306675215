import React from 'react';
import { Topbar, Aside, Main, PageHeader } from 'components';
import { loanTypes, numberFormatter } from 'components/misc/constants';
import { Link } from 'react-router-dom';

function Index() {
    return (
        <main>
            <Topbar />
            
            <div className='pt-16 w-full flex overflow-hidden bg-light-bg dark:bg-dark-page'>
                <Aside />

                <Main>
                    <PageHeader title='Apply for a Loan' size="text-2xl dark:text-white" />

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 md:gap-2 lg:gap-2">
                        {loanTypes.map((loanType, index) => (
                            <Link key={index} to={`/loans/${loanType.path}`} className='loan_card cursor-pointer dark:text-white'>
                                <div className='w-full p-4 inline-flex items-center justify-between'>
                                    <div className='inline-flex flex-col items-start gap-2 overflow-hidden'>
                                        {/* <img src="/images/calculator-banner.jpeg" alt="Loan" className='w-full h-24' /> */}
                                        <span className='text-base font-medium text-gold-700'>
                                            {loanType.name}
                                        </span>
                                        <span className='inline-flex items-center divide-x text-xs'>
                                            <span className='pl-0 px-2'>
                                                Max: {numberFormatter(loanType.limit)}
                                            </span>
                                            <span className='px-2'>
                                                Rate: {loanType.rate}% p.m
                                            </span>
                                        </span>
                                    </div>

                                    <button className='px-3 py-2 text-xs rounded-full border hover:shadow-sm hover:bg-gold-700 hover:text-white hover:border-gold-700' type='button'>
                                        Apply Now
                                    </button>
                                </div>
                            </Link>
                        ))}
                    </div>

                </Main>
            </div>
        </main>
    )
}

export default Index