import { interestRate, loanAmtLimits } from "./loanConstants";

const educationOptions = [
    { 
        value: "Primary", 
        text: "Primary"
    },
    { 
        value: "Junior High", 
        text: "Junior High"
    },
    { 
        value: "Secondary", 
        text: "Secondary"
    },
    { 
        value: "Tertiary", 
        text: "Tertiary"
    }
];

const genderOptions = [
    {
        value : "male",
        text : "Male"
    },
    {
        value : "female",
        text : "Female"
    },
    {
        value : "other",
        text : "Other"
    }
]

const nationalityOptions = [
    {
        value : "ghanaian",
        text : "Ghanaian"
    },
    {
        value : "nigerian",
        text : "Nigerian"
    },
    {
        value : "other",
        text : "Other"
    }
]

const occupationOptions = [
    {
        value : "business",
        text : "Business"
    },
    {
        value : "technology",
        text : "Technology"
    },
    {
        value : "medical",
        text : "Medical"
    },
    {
        value : "service",
        text : "Service"
    },
    {
        value : "other",
        text : "Other"
    }
]

const employmentNatureOptions = [
    {
        value : "fullTime",
        text : "Full Time"
    },
    {
        value : "casual",
        text : "Casual"
    }
]

const networkOptions = [
    {
        value : "mtn",
        text : "MTN"
    },
    {
        value : "vodafone",
        text : "Vodafone / Telecel"
    },
    {
        value : "airtel",
        text : "AirtelTigo"
    },
    {
        value : "glo",
        text : "Glo"
    }
]

const navLinks = [
    {
        path : "/profile",
        text : "Profile"
    }
]

const sidebarLinks = [
    {
        icon : "m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25",
        path : "/",
        text : "Dashboard"
    },
    {
        icon : "M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z",
        path : "/loans",
        text : "Apply for Loan"
    },
    {
        icon : "M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z",
        path : "/profile",
        text : "Profile"
    },
    // {
    //     icon : "M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5",
    //     path : "/repayments",
    //     text : "Repayments"
    // },
    // {
    //     icon : "M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3",
    //     path : "/transactions",
    //     text : "Transactions"
    // }
]

const loanTypes = [
    // { id:"loanme", name:"Unsecured Personal Loan", path : 'loanme', limit : loanAmtLimits.loanme, rate : interestRate.loanme, summary : "Unsecured loan to salary workers backed by a guarantor." },
    { id:"pension", name:"Pension Backed Loan", path : 'pension', limit : loanAmtLimits.pensionLoanLimit, rate : interestRate.pension, summary : "Secure loans using pensions tier-3 as collateral." },
    // { id:"pencash", name:"Pension Backed (Limit 20k)", path : 'pencash', limit : loanAmtLimits.pensionCashLoanLimit, rate : interestRate.pensionCash },
    // { id:"capitalme", name:"Business Loan", path : 'capitalme', limit : loanAmtLimits.businessLoanLimit, rate : interestRate.capitalme },
    // { id:"cediscredit", name:"CedisCredit (Momo Loan)", path : 'cediscredit', limit : loanAmtLimits.creditCashLoanLimit, rate : interestRate.cediscredit, summary: "Convenient repayments via mobile money" },
    // { id:"cashme", name:"Cash-Me Loan", path : 'cashme', limit : loanAmtLimits.cashmeLoanLimit, rate : interestRate.cashme },
    // { id:"invest", name:"Investment Backed Loan", path : 'investment', limit : loanAmtLimits.investCashLoanLimit, rate : interestRate.investmentAchieve },
    { id:"employer", name:"Payroll Deduction Loan", path : 'payroll', limit : loanAmtLimits.employerLoanLimit, rate : interestRate.employer, summary : "Easy repayment through automatic payroll deductions from a payroll processing company or employer." },
    // { id:"ploc", name:"PLOC", path : 'ploc', limit : '', rate :  }
    { id:"salaried", name:"Salaried Loan", path : 'salaried', limit : loanAmtLimits.salariedLoanLimit, rate : interestRate.salaried, summary : "Unsecured loan to salary workers backed by a guarantor." }
]

const options = { year: 'numeric', month: 'short', day: 'numeric' };

const dateToHumanFormat = (date) => {
    return new Date(date).toLocaleDateString(undefined, options);
}

const numberFormatter = (num) => {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const roundNum = (number, precisionIn) => {
    let precision = precisionIn || 2;
    let rounded = (Math.round(number * Math.pow(10, precision)) / Math.pow(10,precision)).toFixed(precision);
    return parseFloat(rounded);
}

const sortSubSectorOpts = (sector) => {
    return sector === "agric" ? argicSubSectorOpts : 
            sector === "mining" ? miningSubSectorOpts :
            sector === "manufacturing" ? manufacturingSubSectorOpts :
            sector === "construction" ? constructionSubSectorOpts :
            sector === "electricity" ? electricitySubSectorOpts :
            sector === "commerce" ? commerceSubSectorOpts :
            sector === "transport" ? transportSubSectorOpts :
            sector === "services" ? servicesSubSectorOpts : [{ value : "other", text : "Other" }];
}

const formatName = (name) => {
    // Remove leading and trailing spaces
    name = name.trim().toLowerCase();

    // Capitalize each first letter
    name = name.replace(/\b\w/g, (letter) => letter.toUpperCase());

    return name;
}



// LoanMe options
const optionsYN = [
    {
        value : "Yes",
        text : "Yes"
    },
    {
        value : "No",
        text : "No"
    }
]

const numOfYearsWithEmployer = [
    {
        value : "below1",
        text : "Below 1 Year"
    },
    {
        value : "max5",
        text : "1 - 5 Years"
    },
    {
        value : "max10",
        text : "5 - 10 Years"
    },
    {
        value : "above10",
        text : "Above 10 Years"
    }
]

const numOfYearsWithEmployerOutput = {
    "below1" : "Below 1 Year",
    "max5" : "1 - 5 Years",
    "max10" : "5 - 10 Years",
    "above10" : "Above 10 Years"
}

const sectorOpts = [
    {
        value : "agric",
        text : "Agriculture"
    },
    {
        value : "industry",
        text : "Industry"
    },
    {
        value : "trade",
        text : "Trade, Repair of Vehicles, Household Goods"
    },
    {
        value : "hotels",
        text : "Hotels and Restaurants"
    },
    {
        value : "transport",
        text : "Transport, Storage and Communications"
    },
    {
        value : "tech",
        text : "Information and Communication"
    },
    {
        value : "financial",
        text : "Financial and Insurance Activities"
    },
    {
        value : "estate",
        text : "Real Estate Activities"
    },
    {
        value : "professional",
        text : "Professional, Administrative and Support Service activities"
    },
    {
        value : "public",
        text : "Public Administration, Defense and Social Security"
    },
    {
        value : "education",
        text : "Education"
    },
    {
        value : "health",
        text : "Health and Social Work"
    },
    {
        value : "other",
        text : "Other Personal Service Activities"
    }
]

const sectorOptsOutput = {
    "agric" : "Agriculture",
    "industry" : "Industry",
    "trade" : "Trade, Repair of Vehicles, Household Goods",
    "hotels" : "Hotels and Restaurants",
    "transport" : "Transport, Storage and Communications",
    "tech" : "Information and Communication",
    "financial" : "Financial and Insurance Activities",
    "estate" : "Real Estate Activities",
    "professional" : "Professional, Administrative and Support Service activities",
    "public" : "Public Administration, Defense and Social Security",
    "education" : "Education",
    "health" : "Health and Social Work",
    "other" : "Other Personal Service Activities"
}

const missedPaymentOptions = [
    {
        value : "noMiss",
        text : "No missed payments"
    },
    {
        value : "max1",
        text : "1 missed payment"
    },
    {
        value : "max2",
        text : "2 missed payments"
    },
    {
        value : "missedMore",
        text : "More than 2 missed payments"
    },
    {
        value : "noCred",
        text : "No payment history"
    
    }
]

const missedPaymentOptionsOutput = {
    "noMiss" : "No missed payments",
    "max1" : "1 missed payment",
    "max2" : "2 missed payments",
    "missedMore" : "More than 2 missed payments",
    "noCred" : "No payment history"
}

const billPaymentOptions = [
    {
        value : "Rent Payments",
        text : "Rent Payments"
    },
    {
        value : "Electricity bills",
        text : "Electricity bills"
    },
    {
        value : "Water bills",
        text : "Water bills"
    },
    {
        value : "Postpaid mobile phone bills",
        text : "Postpaid mobile phone bills"
    },
    {
        value : "Postpaid TV subscription bills",
        text : "Postpaid TV subscription bills"
    },
    {
        value : "Postpaid internet subscription",
        text : "Postpaid internet subscription"
    },
    {
        value : "Tax installment payment",
        text : "Tax installment payment"
    },
    {
        value : "Due-paying association",
        text : "Due-paying association"
    },
    {
        value : "Other goods and services paid on an installment basis",
        text : "Other goods and services paid on an installment basis"
    },
    {
        value : "None of the above",
        text : "None of the above"
    }
]

const billsHistoryOpts = [
    {
        value : "noHist",
        text : "No payment history"
    },
    {
        value : "less3",
        text : "Less than 3 months"
    },
    {
        value : "max2",
        text : "3 months - 2 years"
    },
    {
        value : "max4",
        text : "2 years - 4 years"
    },
    {
        value : "above4",
        text : "Above 4 years"
    }
]

const billsHistoryOptsOutput = {
    "noHist" : "No payment history",
    "less3" : "Less than 3 months",
    "max2" : "3 months - 2 years",
    "max4" : "2 years - 4 years",
    "above4" : "Above 4 years"
}

const insuranceOpts = [
    {
        value : "Employer - Sponsored Insurance",
        text : "Employer - Sponsored Insurance"
    },
    {
        value : "Funeral Insurance for Relatives",
        text : "Funeral Insurance for Relatives"
    },
    {
        value : "Job Loss Insurance",
        text : "Job Loss Insurance"
    },
    {
        value : "Disability Insurance",
        text : "Disability Insurance"
    },
    {
        value : "Comprehensive Property Insurance for Business",
        text : "Comprehensive Property Insurance for Business"
    },
    {
        value : "None",
        text : "None"
    }
]

const purposeOfLoanOpts = [
    {
        value : "Business Expansion",
        text : "Business Expansion"
    },
    {
        value : "Home Improvement",
        text : "Home Improvement"
    },
    {
        value : "Education",
        text : "Education"
    },
    {
        value : "Professional Development",
        text : "Professional Development"
    },
    {
        value : "Medical Expenses",
        text : "Medical Expenses"
    },
    {
        value : "Debt Consolidation",
        text : "Debt Consolidation"
    },
    {
        value : "Investment Opportunity",
        text : "Investment Opportunity"
    },
    {
        value : "Asset Acquisition",
        text : "Asset Acquisition"
    },
    {
        value : "Travel and Vacation",
        text : "Travel and Vacation"
    },
    {
        value : "Emergency Funds",
        text : "Emergency Funds"
    },
    {
        value : "Other",
        text : "Other"
    }
]

const paymentPlanOpts = [
    {
        value : "Daily",
        text : "Daily"
    },
    {
        value : "Weekly",
        text : "Weekly"
    },
    {
        value : "Biweekly",
        text : "Bi-weekly"
    },
    {
        value : "Monthly",
        text : "Monthly"
    },
    {
        value : "Quarterly",
        text : "Quarterly"
    }
]

const paymentDurationOpts = [
    {
        value : "1",
        text : "1 month"
    },
    {
        value : "2",
        text : "2 months"
    },
    {
        value : "3",
        text : "3 months"
    },
    {
        value : "4",
        text : "4 months"
    },
    {
        value : "5",
        text : "5 months"
    },
    {
        value : "6",
        text : "6 months"
    },
    {
        value : "7",
        text : "7 months"
    },
    {
        value : "8",
        text : "8 months"
    },
    {
        value : "9",
        text : "9 months"
    },
    {
        value : "10",
        text : "10 months"
    },
    {
        value : "11",
        text : "11 months"
    },
    {
        value : "12",
        text : "12 months"
    }
]

const incomeFrequencyOpts = [
    {
        value : "Regular",
        text : "Regular"
    },
    {
        value : "Irregular",
        text : "Irregular"
    }
]

const automaticPaymentOpts = [
    {
        value : "Standing Order",
        text : "Standing Order"
    },
    {
        value : "Employer payroll deduction",
        text : "Employer payroll deduction"
    },
    {
        value : "Mobile Money Auto-Deduction",
        text : "Mobile Money Auto-Deduction"
    }
]

const relationshipManagers = [
    {
        value : "m.dogbey@cedispay.com.gh",
        text : "Mark Dogbey"
    },
    {
        value : "afya.agyapomaa@cedispay.com.gh",
        text : "Afya Agyapomaa"
    }
    // {
    //     value : "d.quayson@cedispay.com.gh",
    //     text : "Doris Quayson"
    // },
]

const relationshipManagersOutput = {
    "m.dogbey@cedispay.com.gh" : "Mark Dogbey",
    "d.quayson@cedispay.com.gh" : "Doris Quayson",
    "afya.agyapomaa@cedispay.com.gh" : "Afya Agyapomaa"
}

const balanceOpts = [
    {
        value : "below3k",
        text : "Below 3,000"
    },
    {
        value : "max5k",
        text : "GHS 3,000 - 5,000"
    },
    {
        value : "max10k",
        text : "GHS 5,000 - 10,000"
    },
    {
        value : "max15k",
        text : "GHS 10,000 - 15,000"
    },
    {
        value : "above15k",
        text : "Above GHS 15,000"
    }
]

const balanceOptsOutput = {
    "below3k" : "Below 3,000",
    "max5k" : "GHS 3,000 - 5,000",
    "max10k" : "GHS 5,000 - 10,000",
    "max15k" : "GHS 10,000 - 15,000",
    "above15k" : "Above GHS 15,000"
}

const pensionCompanies = [
    {
        value : "Enterprise Trustees Limited",
        text : "Enterprise Trustees Limited"
    },
    {
        value : "Axis Pension Trust",
        text : "Axis Pension Trust"
    },
    {
        value : "Petra Trust Co. Ltd",
        text : "Petra Trust Co. Ltd"
    },
    {
        value : "United Trustees Gh Ltd",
        text : "United Trustees Gh Ltd"
    },
    {
        value : "Negotiated Benefit Trust Co. Ltd",
        text : "Negotiated Benefit Trust Co. Ltd"
    },
    {
        value : "Metropolitan Pensions Trust Gh Ltd",
        text : "Metropolitan Pensions Trust Gh Ltd"
    },
    {
        value : "Secure Pension Trust",
        text : "Secure Pension Trust"
    },
    {
        value : "Old Mutual Pensions Trust Gh Ltd",
        text : "Old Mutual Pensions Trust Gh Ltd"
    },
    {
        value : "Pensions Alliance Trust Limited",
        text : "Pensions Alliance Trust Limited"
    },
    {
        value : "Capel Pension Trust",
        text : "Capel Pension Trust"
    },
    {
        value : "Others",
        text : "Others"
    }
]


// Pension Options
const pensionContYrsOpts = [
    {
        value : "below1",
        text : "Below 1 Year"
    },
    {
        value : "max5",
        text : "1 - 5 Years"
    },
    {
        value : "max10",
        text : "5 - 10 Years"
    },
    {
        value : "above10",
        text : "Above 10 Years"
    }
]

const pensionContOneYrOpts = [
    {
        value : "monthly",
        text : "Regular and consistent monthly contributions"
    },
    {
        value : "quarterly",
        text : "Regular and consistent quarterly contributions"
    },
    {
        value : "biyearly",
        text : "Regular and consistent contributions every six months"
    },
    {
        value : "occasional",
        text : "Occasional gaps in contributions but mostly consistent throughout the year"
    },
    {
        value : "inconsistent",
        text : "Inconsistent contributions with significant periods of no contributions"
    }
]

const avgPensionContOpts = [
    {
        value : "below500",
        text : "Below GHC 500"
    },
    {
        value : "max1k",
        text : "GHC 500 - GHC 1,000"
    },
    {
        value : "above1k",
        text : "Above GHC 1,000"
    }
]

const pensionCollateralOpts = [
    {
        value : "Investments",
        text : "Petra Opportunity Pension Schema (Investment backed)"
    },
    {
        value : "Savings",
        text : "Savings Booster (Savings backed)"
    },
]

const pensionStateOpts = [
    {
        value : "secured",
        text : "Secured Loan"
    },
    {
        value : "unsecured",
        text : "Unsecured Loan"
    }
]


// CapitalMe options
const momoTransactionOpts = [
    {
        value : "zeroTrans",
        text : "0 transactions" 
    },
    {
        value : "more1",
        text : "1 - 5 transactions"
    },
    {
        value : "more5",
        text : "5 - 10 transactions"
    },
    {
        value : "more15",
        text : "15 - 20 transactions"
    },
    {
        value : "more20",
        text : "Above 20 transactions"
    }
]

const yearsInBusinessOpts = [
    {
        value : "max1",
        text : "One month or less"
    },
    {
        value : "max2",
        text : "1 month - 2 months"
    },
    {
        value : "above3",
        text : "3 months and above"
    }
]

const transFrequencyOpts = [
    {
        value : "freq",
        text : "Frequently (26+ times)"
    },
    {
        value : "regular",
        text : "Regularly (16 - 25 times)"
    },
    {
        value : "rarely",
        text : "Rarely (1 - 5 times), Occasionally"
    }
]

const consistentOpts = [
    {
        value : "consist",
        text : "Consistently low (0% to 40% fluctuation)"
    },
    {
        value : "moderate",
        text : "Moderately fluctuating (41% to 60% variation)"
    },
    {
        value : "somewhat",
        text : "Somewhat fluctuating (61% to 80% variation)"
    },
    {
        value : "volatile",
        text : "Volatile (over 81% variation)"
    }
]

// CedisCredit options
const transPerMonthOpts = [
    {
        value : "5",
        text : "Between 0 - 10 transactions"
    },
    {
        value : "15",
        text : "Between 10 - 20 transactions"
    },
    {
        value : "20",
        text : "Between 20 - 30 transactions"
    },
    {
        value : "30",
        text : "Above 30 transactions"
    }
]

const minimumAmtPerTransOpts = [
    {
        value : "5",
        text : "Between GHC 5 - 10"
    },
    {
        value : "15",
        text : "Between GHC 10 - 20"
    },
    {
        value : "20",
        text : "Between GHC 20 - 30"
    },
    {
        value : "30",
        text : "Above GHC 30"
    }
]

const averageBalanceOpts = [
    {
        value : "below250",
        text : "Below GHC 250"
    },
    {
        value : "max500",
        text : "GHC 250 - 500"
    },
    {
        value : "max750",
        text : "GHC 500 - 750"
    },
    {
        value : "max1k",
        text : "GHC 750 - 1,000"
    },
    {
        value : "above1k",
        text : "Above GHC 1,000"
    }
]

const incomeConsistencyOpts = [
    {
        value : "10",
        text : "Very volatile (Over 80% fluctuation)"
    },
    {
        value : "20",
        text : "Somewhat volatile (60% - 80% fluctuation)"
    },
    {
        value : "30",
        text : "Moderately volatile (40% - 60% fluctuation)"
    },
    {
        value : "40",
        text : "Consistently low (0% - 40% fluctuation)"
    }
]

// Investment options
const collateralOpts = [
    {
        value : "Investments",
        text : "Investments"
    },
    {
        value : "Savings",
        text : "Savings"
    }
]

const investmentSizeOpts = [
    {
        value : "above10",
        text : "Above GHC 20,000"
    },
    {
        value : "max5",
        text : "Between GHC 10,000 - GHC 20,000"
    },
    {
        value : "below5",
        text : "Below GHC 10,000"
    }
]

const investmentFrequencyOpts = [
    {
        value : "max1",
        text : "Once a month or less"
    },
    {
        value : "max3",
        text : "2 - 3 times a month"
    },
    {
        value : "leastOnce",
        text : "At least once a week"
    }
]

const savingsSizeOpts = [
    {
        value : "less10",
        text : "Below GHC 501"
    },
    {
        value : "max20",
        text : "GHC 501 - GHC 1,000"
    },
    {
        value : 'above20',
        text : "Above GHC 1,000"
    }
]

const savingsFrequencyOpts = [
    {
        value : "always",
        text : "Saving every month without fail"
    },
    {
        value : "occasional",
        text : "Saving in most months with occasional lapses"
    },
    {
        value : "irregular",
        text : "Irregular savings with frequent lapses"
    }
]

const transactionFrequencyOpts = [
    {
        value : "everyday",
        text : "Daily or several times a week"
    },
    {
        value : "twiceWeek",
        text : "2 - 3 times a week"
    },
    {
        value : 'onceWeek',
        text : "Once a week or less"
    }
]

const transactionSizeOpts = [
    {
        value : "below500",
        text : "Below GHC 500"
    },
    {
        value : "max1k",
        text : "GHC 500 - GHC 1,000"
    },
    {
        value : 'above1k',
        text : "Above GHC 1,000"
    }
]

const accountAgeOpts = [
    {
        value : "less1yr",
        text : "Less than 1 year"
    },
    {
        value : "max2yr",
        text : "1 - 2 years"
    },
    {
        value : 'max4yr',
        text : "3 - 4 years"
    },
    {
        value : 'above5yr',
        text : "Above 5 years"
    }
]

const accountActiveOpts = [
    {
        value : 'noneWeek',
        text : "Inactive for more than a week"
    },
    {
        value : "onceWeek",
        text : "Once a week or less"
    },
    {
        value : "twiceWeek",
        text : "2 - 3 times a week"
    },
    {
        value : "everyday",
        text : "Daily or several times a week"
    }
]

const accountBalanceOpts = [
    {
        value : "less1Avg",
        text : "Below GHC 100"
    },
    {
        value : "max1Avg",
        text : "GHC 100 - GHC 1,000"
    },
    {
        value : 'max5Avg',
        text : "GHC 1,000 - GHC 5,000"
    },
    {
        value : 'above5Avg',
        text : "Above GHC 5,000"
    }
]

const investmentLoanOpts = [
    {
        value : "achieve",
        text : "Investment / Savings Backed (Achieve)"
    },
    {
        value : "petra",
        text : "Investment / Savings Backed (Petra)"
    }
]

// Payroll options
const payrollLoanOpts = [
    {
        value : "Achieve Customer",
        text : "Achieve Client"
    },
    {
        value : "CedisPay Customer",
        text : "CedisPay Client"
    }
]

// Salaried options
const salariedOpts = [
    {
        value : "Guarantor",
        text : "Guarantor"
    }
]

// PLOC options
const plocYearsInBusinessOpts = [
    {
        value : "below1",
        text : "Below 1 Year"
    },
    {
        value : "max5",
        text : "1 - 5 Years"
    },
    {
        value : "max10",
        text : "5 - 10 Years"
    },
    {
        value : "above10",
        text : "Above 10 Years"
    }
]

const plocOpts = [
    {
        value : 'bank',
        text : 'Bank Statement Only'
    },
    {
        value : "momo",
        text : "Mobile Money Statement Only"
    }
]


const customerTypeOpts = [
    {
        value : "central government",
        text : "Central Government"
    },
    {
        value : "public institutions",
        text : "Public Institutions"
    },
    {
        value : "public enterprises",
        text : "Public Enterprises"
    },
    {
        value : "commerial banks",
        text : "Commerial Banks"
    },
    {
        value : "other depository institutions",
        text : "Other Depository Institutions"
    }
]

const otherFinancialOpts = [
    {
        value : "foreign",
        text : "Foreign"
    },
    {
        value : "indigenous",
        text : "Indigenous"
    },
    {
        value : "households",
        text : "Households"
    }
]

const businessTypeOpts = [
    {
        value : "llc",
        text : "Limited Liability Company (LLC)"
    },
    {
        value : "partnership",
        text : "Partnership"
    },
    {
        value : "soleprop",
        text : "Sole Proprietorship"
    },
    {
        value : "unreg",
        text : "Unregistered"
    }
]

const businessActivityOpts = [
    {
        value : "Essential - general goods ( provision) items",
        text : "Essential - general goods ( provision) items"
    },
    {
        value : "Essentials - perishable foods stuff of 30 days",
        text : "Essentials - perishable foods stuff of 30 days"
    },
    {
        value : "Cosmetic",
        text : "Cosmetic"
    },
    {
        value : "Essential - perishable foods less than 30 days",
        text : "Essential - perishable foods less than 30 days"
    },
    {
        value : "Essential - perishable foods more than 30 days but less than 3 months",
        text : "Essential - perishable foods more than 30 days but less than 3 months"
    },
    {
        value : "Essential not more than 6 months perishable",
        text : "Essential not more than 6 months perishable"
    },
    {
        value : "Essential - non - perishable",
        text : "Essential - non - perishable"
    },
    {
        value : "General supplies",
        text : "General supplies"
    },
    {
        value : "Cooked food",
        text : "Cooked food"
    },
    {
        value : "Clothing",
        text : "Clothing"
    },
    {
        value : "Building materials",
        text : "Building materials"
    },
    {
        value : "Car parts",
        text : "Car parts"
    },
    {
        value : "Uber/taxi or transportation services",
        text : "Uber/taxi or transportation services"
    },
    {
        value : "Dress making",
        text : "Dress making"
    },
    {
        value : "Make-up",
        text : "Make-up"
    },
    {
        value : "Other, please specify",
        text : "Other, please specify"
    },
]

const businessSectorOpts = [
    {
        value : "agric",
        text : "Agriculture, Forestry & Fishing"
    },
    {
        value : "mining",
        text : "Mining & Quarrying"
    },
    {
        value : "manufacturing",
        text : "Manufacturing"
    },
    {
        value : "construction",
        text : "Construction"
    },
    {
        value : "electricity",
        text : "Electricity, Gas & Water"
    },
    {
        value : "commerce",
        text : "Commerce & Finance"
    },
    {
        value : "transport",
        text : "Transport, Storage & Communication"
    },
    {
        value : "services",
        text : "Services"
    },
    {
        value : "misc",
        text : "Miscellaneous"
    }
]

const businessSubSectorOpts = [
    {
        value : "cocoa",
        text : "Cocoa Production"
    },
    {
        value : "livestock",
        text : "Livestock Breeding"
    },
    {
        value : "poultry",
        text : "Poultry Farming"
    },
    {
        value : "forestry",
        text : "Forestry"
    },
    {
        value : "logging",
        text : "Logging"
    },
    {
        value : "fishing",
        text : "Fishing"
    },
    {
        value : "other",
        text : "Other"
    },
]

const argicSubSectorOpts = [
    {
        value : "cocoa",
        text : "Cocoa Production"
    },
    {
        value : "livestock",
        text : "Livestock Breeding"
    },
    {
        value : "poultry",
        text : "Poultry Farming"
    },
    {
        value : "forestry",
        text : "Forestry"
    },
    {
        value : "logging",
        text : "Logging"
    },
    {
        value : "fishing",
        text : "Fishing"
    },
    {
        value : "other",
        text : "Other"
    },
]

const miningSubSectorOpts = [
    {
        value : "bauxite",
        text : "Bauxite Mining"
    },
    {
        value : "diamond",
        text : "Diamond Mining"
    },
    {
        value : "gold",
        text : "Gold Mining"
    },
    {
        value : "manganese",
        text : "Manganese Mining"
    },
    {
        value : "quarrying",
        text : "Quarrying"
    },
    {
        value : "other",
        text : "Other"
    },

]

const manufacturingSubSectorOpts = [
    {
        value : "export",
        text : "For Export"
    },
    {
        value : "food",
        text : "Food, Drink & Tobacco"
    },
    {
        value : "textiles",
        text : "Textile, Clothing and Footwear"
    },
    {
        value : "sawmilling",
        text : "Sawmilling & Wood Processing"
    },
    {
        value : "paper",
        text : "Paper, Pulp & Paper Products"
    },
    {
        value : "chemicals",
        text : "Chemicals & Fertilizer Products"
    },
    {
        value : "iron",
        text : "Iron & Steel Products"
    },
    {
        value : "boat",
        text : "Boat / Ship Building & Repair" 
    },
    {
        value : "motor",
        text : "Motor Vehicles"
    },
    {
        value : "other",
        text : "Other"
    },
]

const constructionSubSectorOpts = [
    {
        value : "works",
        text : "Construction & Works"
    },
    {
        value : "building",
        text : "Building Construction"
    }
]

const electricitySubSectorOpts = [
    {
        value : "electric",
        text : "Electric, Light & Power"
    },
    {
        value : "water",
        text : "Water Supply"
    },
    {
        value : "gas",
        text : "Gas Manufacture & Distribution"
    }
]

const commerceSubSectorOpts = [
    {
        value : "import",
        text : "Import Trade"
    },
    {
        value : "export",
        text : "Export Trade"
    },
    {
        value : "cocoa",
        text : "Cocoa Marketing"
    },
    {
        value : "timber",
        text : "Timber Marketing"
    },
    {
        value : "diamond",
        text : "Diamond Marketing"
    },
    {
        value : "mortgage",
        text : "Mortgage Financing"
    },
    {
        value : "otherFinancial",
        text : "Other Financial Institution"
    },
    {
        value : "other",
        text : "Other Unclassified"
    }
]

const transportSubSectorOpts = [
    {
        value : "road",
        text : "Road Transport"
    },
    {
        value : "rail",
        text : "Rail Transport"
    },
    {
        value : "ocean",
        text : "Ocean & Other Water Transport"
    },
    {
        value : "air",
        text : "Air Transport"
    },
    {
        value : "storage",
        text : "Storage & Warehousing"
    },
    {
        value : "communications",
        text : "Communications"
    }
]

const servicesSubSectorOpts = [
    {
        value : "printing",
        text : "Printing, Publishing & Allied Products"
    },
    {
        value : "business",
        text : "Business Services"
    },
    {
        value : "recreation",
        text : "Recreation Services"
    },
    {
        value : "personal",
        text : "Personal Services"
    },
    {
        value : "salary",
        text : "Salary Credit"
    },
    {
        value : "other",
        text : "Other Services(including government)"
    }
]

const employmentLevelOpts = [
    {
        value : "top",
        text : "Top"
    },
    {
        value : "middle",
        text : "Middle"
    },
    {
        value : "lower",
        text : "Lower"
    }
]

const employmentTypeOpts = [
    {
        value : "skilled",
        text : "Skilled"
    },
    {
        value : "unskilled",
        text : "Unskilled"
    }
]

const employmentNatureOpts = [
    {
        value : "fullTime",
        text : "Full Time"
    },
    {
        value : "casual",
        text : "Casual"
    }
]

const demandGoodsOpts = [
    {
        value : "essential",
        text : "Essential"
    },
    {
        value : "nonEssential",
        text : "Non-Essential"
    }
]


const creditConClass = (creditScore) => {
    return creditScore === "Acceptable" ? "bg-emerald-400" : 
        creditScore === "Satisfactory" ? "bg-yellow-400" : 
        creditScore === "Not Satisfactory" ? "bg-red-400" : 
        "bg-gray-400";
}

const maxFileSize5 = 1024 * 1024 * 5; // 5MB

export {
    educationOptions,
    genderOptions,
    nationalityOptions,
    occupationOptions,
    employmentNatureOptions,
    networkOptions,
    navLinks,
    sidebarLinks,
    loanTypes,
    dateToHumanFormat,
    numberFormatter,
    roundNum,
    sortSubSectorOpts,
    formatName,

    // LoanMe options
    optionsYN,
    numOfYearsWithEmployer,
    numOfYearsWithEmployerOutput,
    sectorOpts,
    sectorOptsOutput,
    missedPaymentOptions,
    missedPaymentOptionsOutput,
    billPaymentOptions,
    billsHistoryOpts,
    billsHistoryOptsOutput,
    insuranceOpts,
    purposeOfLoanOpts,
    paymentPlanOpts,
    paymentDurationOpts,
    incomeFrequencyOpts,
    automaticPaymentOpts,
    relationshipManagers,
    relationshipManagersOutput,
    balanceOpts,
    balanceOptsOutput,
    pensionCompanies,

    // Pension Options
    pensionContYrsOpts,
    pensionContOneYrOpts,
    avgPensionContOpts,
    pensionCollateralOpts,
    pensionStateOpts,

    // CapitalMe options
    momoTransactionOpts,
    yearsInBusinessOpts,
    transFrequencyOpts,
    consistentOpts,

    // CedisCredit options
    transPerMonthOpts,
    minimumAmtPerTransOpts,
    averageBalanceOpts,
    incomeConsistencyOpts,

    // Investment options
    collateralOpts,
    investmentSizeOpts,
    investmentFrequencyOpts,
    savingsSizeOpts,
    savingsFrequencyOpts,
    transactionFrequencyOpts,
    transactionSizeOpts,
    accountAgeOpts,
    accountActiveOpts,
    accountBalanceOpts,
    investmentLoanOpts,

    // Payroll options
    payrollLoanOpts,

    // Salaried options
    salariedOpts,

    // PLOC options
    plocYearsInBusinessOpts,
    plocOpts,

    creditConClass,

    customerTypeOpts,
    otherFinancialOpts,
    businessTypeOpts,
    businessActivityOpts,
    businessSectorOpts,
    businessSubSectorOpts,
    employmentLevelOpts,
    employmentTypeOpts,
    employmentNatureOpts,
    demandGoodsOpts,

    maxFileSize5
}