import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { dateToHumanFormat, numberFormatter } from 'components/misc/constants';
import { Button } from "components"
import { useUser } from 'hooks';
import { fetchRequests } from 'https/requests';
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { nextPaymentDate } from 'components/misc/functions';

function Repayment({ loan }) {

    const totalPaymentsMade = loan?.transactions?.filter(l => l?.type !== "disburse").reduce((acc, curr) => acc + parseFloat(curr?.amount), 0).toFixed(2);
    const remainingBalance = (loan?.accountInfo?.totalPayment - totalPaymentsMade).toFixed(2);
    const toPay = loan?.accountInfo?.premium < remainingBalance ? loan?.accountInfo?.premium : remainingBalance;
    const nextPaymentDay = loan && nextPaymentDate(loan);

    const { accessToken } = useUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState({});
    const [verify, setVerify] = useState(false);
    const [counter, setCounter] = useState(30);
    const [success, setSuccess] = useState(false);

    function closeModal() {
        setSuccess(false);
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true)
    }

    const handleChargePayment = async() => {
        setIsLoading(true);

        let url = `${process.env.REACT_APP_STAGING_PAYMENT}/initiate-repayment`;

        const headers = {
            "Authorization" : `${accessToken}`,
            "Content-Type": "application/json"
        }

        const data = {
            _id : loan?._id,
            type : loan?.type.toLowerCase(),
            state : loan?.state,
            amount : toPay,
            phone : loan?.phone
        }

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setIsLoading(false);
        
        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                state : true,
                message : `Your session has expired, please login to continue.`,
                type : "token"
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                state : true,
                message : `${res?.err}`,
                type : "error"
            }))
            return;
        }

        setDetails(res);
        setVerify(true);

        // timer to enable verify button after 30 seconds
        const startTimer = () => {
            const timer = setInterval(() => {
                setCounter(prevCounter => prevCounter - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        };
        
        if(counter > 0){
            startTimer();
        }

    }

    const handleConfirmPayment = async() => {
        setIsLoading(true);

        let url = `${process.env.REACT_APP_STAGING_PAYMENT}/confirm-repayment`;

        const headers = {
            "Authorization" : `${accessToken}`,
            "Content-Type": "application/json"
        }

        const data = {
            _id : loan?._id,
            loanId : loan?.loanId,
            type : loan?.type.toLowerCase(),
            phone : loan?.phone,
            referenceId : details.referenceId,
            token : details.token
        }

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setIsLoading(false);
        
        if(res?.code === 401){
			navigate("/login");
            dispatch(show({
                state : true,
                message : `Your session has expired, please login to continue.`,
                type : "token"
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                state : true,
                message : `${res?.err}`,
                type : "error"
            }))
            return;
        }

        if(res?.path){
            setSuccess(`${res?.message}`);
            setTimeout(() => {
                navigate(`/`, { replace: true });
            }, 2500);
            return;
        }

        setSuccess(`${res?.message}`);

        setTimeout(() => {
            window.location.reload();
        }, 2500);

    }

    return (
		<>
            <div className="md:hidden lg:hidden py-6 px-6 border-2 border-gray-300/[.3] dark:border-white/[.2] rounded-md w-full mb-4 md:mb-12 lg:mb-12 flex flex-col items-center justify-between">
                <div className='inline-flex items-center divide-x-2'>
                    <div className='px-4 pl-0'>
                        <span>Total Payment:</span> <span className='font-semibold text-lg'>{numberFormatter(loan?.accountInfo?.totalPayment)}</span>
                    </div>
                    
                    <div className='px-4'>
                        <span>Payments made:</span> <span className='font-semibold'>{numberFormatter(totalPaymentsMade)}</span>
                    </div>
                </div>

                {loan?.adminStage === "active" && (
                    <button type="button" className="rounded-md bg-gold-700 hover:bg-gold-500 text-sm text-white px-4 py-2 mt-6" onClick={openModal}>
                        <span>Make payment</span>
                    </button>
                )}
            </div>

            <div className='hidden md:grid lg:grid md:grid-cols-2 lg:grid-cols-4 gap-6'>
                <div className="mini_card">
                    <div className='flex flex-row items-center justify-between'>
                        <span className='text-slate-700 dark:text-slate-50 text-sm'>Total Payment</span>

                        <span className='text-slate-700 dark:text-slate-50'>
                            ₵
                        </span>
                    </div>

                    <h4 className='text-3xl font-bold text-slate-700 dark:text-slate-50 mt-2'>
                        {numberFormatter(loan?.accountInfo?.totalPayment)}
                    </h4>
                </div>

                <div className="mini_card">
                    <div className='flex flex-row items-center justify-between'>
                        <span className='text-slate-700 dark:text-slate-50 text-sm'>Payments made</span>

                        <span className='text-slate-700 dark:text-slate-50'>
                            ₵
                        </span>
                    </div>

                    <h4 className='text-3xl font-bold text-slate-700 dark:text-slate-50 mt-2'>
                        {numberFormatter(totalPaymentsMade)}
                    </h4>
                </div>

                <div className="mini_card">
                    <div className='flex flex-row items-center justify-between'>
                        <span className='text-slate-700 dark:text-slate-50 text-sm'>Next payment date</span>

                        <span className='text-slate-700 dark:text-slate-50'>
                            <CalendarDaysIcon className='w-5 h-5' />
                        </span>
                    </div>

                    <h4 className='text-3xl font-bold text-slate-700 dark:text-slate-50 mt-2'>
                        {dateToHumanFormat(nextPaymentDay)}
                    </h4>
                </div>

                <div className="mini_card">
                    {loan?.state !== "completed" ? (
                        <>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='text-slate-700 dark:text-slate-50 text-sm'>
                                {loan?.state !== "active" ? `${loan?.paymentPlan} Payment` : "Make payment"}
                            </span>

                            <span className='text-slate-700 dark:text-slate-50'>
                                ₵
                            </span>
                        </div>

                        <h4 className='text-3xl font-bold text-slate-700 dark:text-slate-50 mt-2 flex items-center'>
                            {numberFormatter(toPay)} 

                            {loan?.adminStage === "active" && (
                            <button type="button" className="rounded-md bg-gold-700 hover:bg-gold-500 text-xs text-white px-3 py-2 ml-auto" onClick={openModal}>
                                <span>Make payment</span>
                            </button>
                            )}
                        </h4>
                        </>
                    ) : (
                        <>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='text-slate-700 dark:text-slate-50 text-sm'>
                                Loan Status
                            </span>

                            <span className='text-slate-700 dark:text-slate-50'>
                                ₵
                            </span>
                        </div>

                        <h4 className='text-3xl font-bold text-emerald-500 dark:text-emerald-500 mt-2 flex items-center'>
                            Paid in full
                        </h4>
                        </>
                    )}
                </div>
            </div>


            {/* days in arrears counter */}
            {loan?.daysInDelinq && loan?.daysInDelinq > 0 ? (
                <div className='mt-6'>
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">Notice:</strong>
                        <span className="block sm:inline"> You are <span className='font-bold'>{loan?.daysInDelinq}</span> days behind in payments. Please make payment to avoid penalties.</span>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {/* days in arrears counter */}
            

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-30" onClose={closeModal}>
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black/25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<Dialog.Title
										as="h3"
										className="text-lg font-medium leading-6 text-gray-900 mb-8"
									>
										Confirm payment
									</Dialog.Title>
									<div className="mt-2 space-y-3">
										<div className="text-sm text-gray-500">
											<span className='text-sm'>Transfer to:</span>
                                            <h3 className='text-lg font-semibold text-gray-900'>CedisPay Micro-Finance Limited</h3>
										</div>

										<div className="text-sm text-gray-500">
											<span className='text-sm'>Amount to transfer:</span>
                                            <h3 className='text-lg font-semibold text-gray-900'>GHC {numberFormatter(toPay)}</h3>
										</div>

										<div className="text-sm text-gray-500">
											<span className='text-sm'>From:</span>
                                            <h3 className='text-lg font-semibold text-gray-900'>{loan?.phone}</h3>
										</div>

                                        <div className='bg-slate-100 rounded-md px-3 py-2'>
                                            <p className="text-sm dark:text-gray-700">
                                                <span className='font-bold'>Note:</span> If you do not receive a prompt to authorize the payment, please dial <span className='font-bold'>*170#</span>, select <span className='font-bold'>option 6</span> and <span className='font-bold'>option 3</span> to authorize the payment.
                                            </p>
                                        </div>

									</div>

                                    {!verify ? (
                                        <div className="mt-8 space-x-4">
                                            <Button 
                                                btnText={"Pay Now"}
                                                btnType={"button"}
                                                btnClasses={"inline-flex justify-center rounded-md border border-transparent bg-gold-500 px-4 py-2 text-sm font-medium text-white hover:bg-gold-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"}
                                                btnFunction={handleChargePayment}
                                                processing={isLoading}
                                            />

                                            <Button 
                                                btnText={"Cancel"}
                                                btnType={"button"}
                                                btnClasses={"inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-slate-700 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"}
                                                btnFunction={closeModal}
                                            />
                                        </div>
                                    ) : (
                                        <div className="mt-8 space-x-4">
                                            <Button 
                                                btnText={`Verify Payment ${counter > 0 ? `(${counter}s)` : ''}`}
                                                btnType={"button"}
                                                btnClasses={"inline-flex justify-center rounded-md border border-transparent bg-gold-500 px-4 py-2 text-sm font-medium text-white hover:bg-gold-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 w-full"}
                                                btnFunction={handleConfirmPayment}
                                                disabled={counter > 0}
                                                processing={isLoading}
                                            />
                                        </div>
                                    
                                    )}

                                    {success && (
                                        <div className="mt-4 text-sm bg-emerald-400 text-white py-2 px-3 rounded-md">
                                            {success}
                                        </div>
                                    )}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}

export default Repayment