import { useEffect, useState } from 'react'
import useUser from './useUser';
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
import { getRequest } from 'https/requests';
import { useNavigate } from 'react-router-dom';

function useLoans() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [loans, setLoans] = useState([]);
    const { accessToken, idToken } = useUser();

    useEffect(() => {

        const fetchLoans = async(accessToken, idToken) => {
            setIsLoading(true);

            const url = `${process.env.REACT_APP_STAGING_AUTH}/loans`;

            try {

                let res = await getRequest(url, accessToken, idToken, {});
                
                if(res?.code === 401){
                    navigate("/login");
                    dispatch(show({
                        state : true,
                        message : `${res?.err}, Please login again`,
                        type : "error"
                    }))
                    return;
                }

                if(res?.err){
                    dispatch(show({
                        state : true,
                        message : `${res?.err}`,
                        type : "token"
                    }))
                    return;
                }

                setLoans(res)
                
            } catch (error) {
                dispatch(show({
                    state : true,
                    message : `${error}`,
                    type : "token"
                }))
            } finally {
                setIsLoading(false);
            }
        }

        fetchLoans(accessToken, idToken);

    }, [dispatch, navigate, accessToken, idToken])

    return { isLoading, loans }
}

export default useLoans