import { roundNum } from "../constants";
import { workYears } from "../loanConstants";


// ----- CONSTANTS ----- //
// scoring dti 
const scoringDTIStatus = {
    below35 : 30,
    upTo43 : 20,
    upTo50 : 15,
    above51 : 5
}

// savings balance
const loanMeIncomeRange = {
    above15k : 25,
    max15k : 20,
    max10k : 15,
    max5k : 10,
    below3k : 5
}

// income values
const loanMeIncomeScores = {
    above10k : 40,
    max10k : 30,
    max5k : 20,
    below2k : 10
}

// past 3 months frequency of savings
const savingsHabitScore = {
    Yes : 30,
    No : 0
}

// automated payments scoring details
const automatedPaymentsObj = {
    Yes : 30,
    No : 0
}

// recent 3 months loan and bills payment history
const loanPaymentHstObj = {
    Yes : 30,
    No : 0
}

// credit status for loan and bills payment history
const creditStatusLoanBills = {
    noMiss : 30,
    max1 : 20,
    max2 : 5,
    missedMore : 0,
    noCred : 0
}

// recent 3 months bills payment history
const billsPaymentHstObj = {
    Yes : 30,
    No : 0
}


//  ---- SCORING CARDS AND WEIGHTS -----  //
// new scoring dti values
const scoringDTIDenom = Math.max(...Object.values(scoringDTIStatus));
const scoringDTIMaxWgt = 15;

// savings balance
const loanMeIncomeDenom = Math.max(...Object.values(loanMeIncomeRange));
const loanMeIncomeMaxWgt = 15;

// new loanme income values
const incomeFlowMax = Math.max(...Object.values(loanMeIncomeScores));
const loanmeIncomeMaxWeight = 20;

// past 3 months frequency of savings
const savingsDenom = Math.max(...Object.values(savingsHabitScore));
const savingsMaxWgt = 5;

// years with current employer 
const workYearsDenom = Math.max(...Object.values(workYears))
const workYearsMaxWgt = 10;

// automated payments scoring
const automatedDenom = Math.max(...Object.values(automatedPaymentsObj));
const automatedPaymentsMaxWgt = 10;

// recent 3 months loan payment history
const loanPaymentHstDenom = Math.max(...Object.values(loanPaymentHstObj));
const loanPaymentMaxWgt = 10;

// credit status loan payment history
const creditStatusLoanBillsDenom = Math.max(...Object.values(creditStatusLoanBills))
const creditStatusLoanPaymentMaxWgt = 5;

// recent 3 months bills payment history
const billsPaymentHstDenom = Math.max(...Object.values(billsPaymentHstObj));
const billsPaymentMaxWgt = 5;

// credit status bills payment history
const creditStatusBillsPaymentMaxWgt = 5;


// get the total score given inputs 
async function getLoanMeScore(scoringDTI, income, savings, yearsAtWork, automatedPaymentsStatus, freqIncome, paymentHstWCedisPay, billsHistory, billsHistoryStatus, momoBalance) {
    console.log(`scoringDTI: ${scoringDTI}, income: ${income}, savings: ${savings}, yearsAtWork: ${yearsAtWork}, automatedPaymentsStatus: ${automatedPaymentsStatus}, freqIncome: ${freqIncome}, paymentHstWCedisPay: ${paymentHstWCedisPay}, billsHistory: ${billsHistory}, billsHistoryStatus: ${billsHistoryStatus}, momoBalance: ${momoBalance}`)

    // 1. Debt to income ratio
    let scoringDTIRaw = scoringDTI <= 35 ? scoringDTIStatus.below35 : 
                        scoringDTI >= 36 && scoringDTI <= 43 ? scoringDTIStatus.upTo43 : 
                        scoringDTI >= 44 && scoringDTI <= 50 ? scoringDTIStatus.upTo50 : 
                        scoringDTIStatus.above51;
    let scoringDTIScore = (scoringDTIRaw / scoringDTIDenom) * scoringDTIMaxWgt;
    scoringDTIScore = roundNum(scoringDTIScore, 2);

    // 2. Savings Balance
    let savingsBalRaw = loanMeIncomeRange[momoBalance];
    let savingsBalScore = (savingsBalRaw / loanMeIncomeDenom) * loanMeIncomeMaxWgt;
    savingsBalScore = roundNum(savingsBalScore, 2);

    // 3. Income  
    let normIncomeScore = (income/ incomeFlowMax)*loanmeIncomeMaxWeight;
    normIncomeScore = roundNum(normIncomeScore, 2);

    // 4. Past 3 months frequency of savings 
    let savingsRaw = savingsHabitScore[savings];
    let savingsScore = (savingsRaw / savingsDenom) * savingsMaxWgt;
    savingsScore = roundNum(savingsScore, 2);

    // 5. Years with current employer
    let normAgeScoreRaw = yearsAtWork;
    let normAgeScore = (normAgeScoreRaw/workYearsDenom) * workYearsMaxWgt;
    normAgeScore = roundNum(normAgeScore, 2);

    // 6. Automated payments 
    let automatedRaw = automatedPaymentsObj[automatedPaymentsStatus];
    let automatedScore = (automatedRaw / automatedDenom) * automatedPaymentsMaxWgt;
    automatedScore = roundNum(automatedScore, 2);

    // 7. Recent 3 months loan payment history 
    let freqIncomeScore = loanPaymentHstObj[freqIncome];
    let normFreqIncomeScore = (freqIncomeScore/loanPaymentHstDenom) * loanPaymentMaxWgt;
    normFreqIncomeScore = roundNum(normFreqIncomeScore, 2);
    
    // 8. Credit status of loan payment history 
    let DIRscoreRaw = creditStatusLoanBills[paymentHstWCedisPay];
    let DIRscore = (DIRscoreRaw / creditStatusLoanBillsDenom) * creditStatusLoanPaymentMaxWgt;
    DIRscore = roundNum(DIRscore, 2);

    // 9. Recent 3 months bills payment history
    let billsHistoryRaw = billsPaymentHstObj[billsHistory];
    let billsHistoryScore = (billsHistoryRaw / billsPaymentHstDenom) * billsPaymentMaxWgt;
    billsHistoryScore = roundNum(billsHistoryScore, 2)
    
    // 10. Credit status of bills payment history
    let billsHistoryStatusRaw = creditStatusLoanBills[billsHistoryStatus];
    let billsHistoryStatusScore = (billsHistoryStatusRaw / creditStatusLoanBillsDenom) * creditStatusBillsPaymentMaxWgt;
    billsHistoryStatusScore = roundNum(billsHistoryStatusScore, 2);


    console.log(`Scores. DTI: ${scoringDTIScore}, Savings Balance: ${savingsBalScore}, income: ${normIncomeScore}, savings: ${savingsScore}, years with employer: ${normAgeScore}, automated payments: ${automatedScore}, 3 months loan payment history: ${normFreqIncomeScore}, credit status loan payments: ${DIRscore}, 3 months bills payment history: ${billsHistoryScore}, credit status bills payments: ${billsHistoryStatusScore}`);

    let totalScore = scoringDTIScore + savingsBalScore + normIncomeScore + savingsScore + normAgeScore + automatedScore + normFreqIncomeScore + DIRscore + billsHistoryScore + billsHistoryStatusScore;

    totalScore = roundNum(totalScore, 2);

    return {
        score: totalScore,
    }
}

export { 
    getLoanMeScore
}