import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { FormInput, FormSelect, FormUpload, Button, Modal, EligCheck, OtherDocsUpload } from 'components';
import { optionsYN, numOfYearsWithEmployer, sectorOpts, missedPaymentOptions, billPaymentOptions, billsHistoryOpts, insuranceOpts, purposeOfLoanOpts, paymentPlanOpts, paymentDurationOpts, incomeFrequencyOpts, automaticPaymentOpts, relationshipManagers, balanceOpts } from 'components/misc/constants';
import { preCheckLoanEligibility } from "../../misc/loanPreChecks";
import { getDateAfterTimeframe, nextMonthStartDate } from 'components/misc/functions';
import { loanAmtLimits } from 'components/misc/loanConstants';
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
// import { useLocation } from 'hooks';
import LoanRequirement from './LoanRequirement';
import ReviewModal from 'components/Utils/ReviewModal';

function LoanMe({ client }) {

    const dispatch = useDispatch();
    // const { city, country, countryCode, lat, lon, region, regionCode, timezone, zip } = useLocation();
    
    const fullName = client?.otherNames + " " + client?.surname;

    const [isOpen, setIsOpen] = useState(false);
    const [isLoanReqOpen, setIsLoanReqOpen] = useState(false);
    const [reviewModalOpen, setReviewModalOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false)
    }
    
    const openModal = () => {
        setIsOpen(true)
    }

    const [employedCurrently, setEmployedCurrently] = useState('');
    const [salaryStatus, setSalaryStatus] = useState('');
    const [incomeStatus, setIncomeStatus] = useState('');
    const [automatedPaymentsStatus, setAutomatedPaymentsStatus] = useState('');
    const [incomeAmt, setIncomeAmt] = useState('');
    const [debtStatus, setDebtStatus] = useState('');
    const [debtAmt, setDebtAmt] = useState(debtStatus === "Yes" ? '' : 0);
    const [momoBalance, setMomoBalance] = useState('');
    const [employerName, setEmployerName] = useState('');
    const [yearsAtWork, setYearsAtWork] = useState('');
    const [employmentSector, setEmploymentSector] = useState('');
    const [outstandingLoans, setOutstandingLoans] = useState('');
    const [loansTaken, setLoansTaken] = useState('');
    const [loanDefaultStatus, setLoanDefaultStatus] = useState('');
    const [defaultedLoanStatus, setDefaultedLoanStatus] = useState('');
    const [billsOption, setBillsOption] = useState('');
    const [billsHistory, setBillsHistory] = useState('');
    const [billsHistoryStatus, setBillsHistoryStatus] = useState('');
    const [loanPaymentHistory, setLoanPaymentHistory] = useState('');
    const [billPaymentHistory, setBillPaymentHistory] = useState('');
    const [savingHabits, setSavingHabits] = useState('');
    const [savingsFreq1, setSavingsFreq1] = useState('');
    const [savingsFreq2, setSavingsFreq2] = useState('');
    const [savingsFreq3, setSavingsFreq3] = useState('');
    const [typeOfInsurance, setTypeOfInsurance] = useState('');
    const [eligibleSavingsHabit, setEligibleSavingsHabit] = useState('');
    const [amountReq, setAmountReq] = useState('');
    const [purpose, setPurpose] = useState('');
    const [paymentPlan, setPaymentPlan] = useState('');
    const [paymentDuration, setPaymentDuration] = useState('');
    const [repaySource, setRepaySource] = useState('');
    const [freqIncome, setFreqIncome] = useState('');
    const [autoDeduction, setAutoDeduction] = useState('');
    const [incomeProof, setIncomeProof] = useState();
    const [bankOrMomoStatement, setBankOrMomoStatement] = useState();
    const [otherDocs, setOtherDocs] = useState([]);
    const [startDate, setStartDate] = useState(getDateAfterTimeframe('daily'));
    const [relManager, setRelManager] = useState('');
    const [guarantorFirstName, setGuarantorFirstName] = useState('');
    const [guarantorLastName, setGuarantorLastName] = useState('');
    const [guarantorPhone, setGuarantorPhone] = useState('');
    const [guarantorAddress, setGuarantorAddress] = useState('');
    const [guarantorRelationship, setGuarantorRelationship] = useState('');
    const guarantorIdType = 'GhanaCard';
    const [guarantorIdNumber, setGuarantorIdNumber] = useState('');
    const [guarantorDigitalAddress, setGuarantorDigitalAddress] = useState('');
    const [guarantorDob, setGuarantorDob] = useState('');
    const signedName = fullName;
    const [processing, setProcessing] = useState(false);
    const [preCheckData, setPreCheckData] = useState({});

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);

        if(amountReq > loanAmtLimits.loanme){
            dispatch(show({
                state : true,
                message : `Loan amount requested exceeds the limit of GHC${loanAmtLimits.loanme}`,
                type : "error"
            }))
            setProcessing(false);
            return;
        }

        // check to see if files have been uploaded
        let incomeFile = document.getElementById("incomeProof");
        let bankFile = document.getElementById("bankOrMomoStatement");

        if(!incomeFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach proof of income"
            }))
            return;
        }

        if(!bankFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach bank or momo statement"
            }))
            return;
        }

        let data = { employedCurrently, salaryStatus, incomeStatus, automatedPaymentsStatus, incomeAmt, debtAmt, momoBalance, employerName, yearsAtWork, employmentSector, outstandingLoans, loansTaken, loanDefaultStatus, defaultedLoanStatus, billsOption, billsHistory, billsHistoryStatus, loanPaymentHistory, billPaymentHistory, savingHabits, savingsFreq1, savingsFreq2, savingsFreq3, typeOfInsurance, eligibleSavingsHabit, amountReq, purpose, paymentPlan, paymentDuration, repaySource, freqIncome, autoDeduction, incomeProof, bankOrMomoStatement, otherDocs, startDate, relManager, guarantorFirstName, guarantorLastName, guarantorPhone, guarantorAddress, guarantorRelationship, guarantorIdType, guarantorIdNumber, guarantorDigitalAddress, guarantorDob, signedName }

        // precheck loan application eligibility
        let response = await preCheckLoanEligibility("loanMe", data);
        setPreCheckData({ ...response, data });

        setTimeout(() => {
            setProcessing(false);
            openModal();
        }, 2000);
    }

    useEffect(() => {
        // open loan requirements modal
        setIsLoanReqOpen(true);

    },[]);

    return (
        <>
        <LoanRequirement 
            isOpen={isLoanReqOpen}
            setIsLoanReqOpen={setIsLoanReqOpen}
            type="LoanMe"
            requirements={[
                "Proof of Income (Payslip)",
                "3 Months Bank or Mobile Money Statement",
            ]}
        />

        <div className='display_card'>
            <form className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-2' encType={'multipart/form-data'} onSubmit={handleRequest}>
                <div className='col-span-full'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Income Information
                    </h3>
                </div>

                <FormSelect 
                    label='Are you employed with your current employer for at least 1 year?'
                    name='employedCurrently'
                    id="employedCurrently"
                    content={optionsYN}
                    value={employedCurrently}
                    setValue={setEmployedCurrently}
                    required={true}
                />
                
                <FormSelect 
                    label='Is your regular monthly income equal to or greater than GHC2,000 per month?'
                    name='salaryStatus'
                    id="salaryStatus"
                    content={optionsYN}
                    value={salaryStatus}
                    setValue={setSalaryStatus}
                    required={true}
                />
                
                <FormSelect 
                    label='Do you receive regular monthly income (Employment or other income)?'
                    name='incomeStatus'
                    id="incomeStatus"
                    content={optionsYN}
                    value={incomeStatus}
                    setValue={setIncomeStatus}
                    required={true}
                />

                <FormSelect 
                    label='Are your loan payment automated?'
                    name='automatedPaymentsStatus'
                    id="automatedPaymentsStatus"
                    content={optionsYN}
                    value={automatedPaymentsStatus}
                    setValue={setAutomatedPaymentsStatus}
                    required={true}
                />

                <FormInput 
                    label='Provide your monthly regular income'
                    name='incomeAmt'
                    id="incomeAmt"
                    type='number'
                    value={incomeAmt}
                    setValue={setIncomeAmt}
                    required={true}
                />

                <FormSelect 
                    label='Are you currently paying any loans(hire purchase payments)?'
                    name='debtStatus'
                    id="debtStatus"
                    content={optionsYN}
                    value={debtStatus}
                    setValue={setDebtStatus}
                    required={true}
                />

                {debtStatus === "Yes" && (
                <FormInput 
                    label='How much is your debt?'
                    name='debtAmt'
                    id="debtAmt"
                    type='number'
                    value={debtAmt}
                    setValue={setDebtAmt}
                />
                )}

                <FormSelect 
                    label='What is the cash balance in your savings account?'
                    name='momoBalance'
                    id="momoBalance"
                    content={balanceOpts}
                    value={momoBalance}
                    setValue={setMomoBalance}
                    required={true}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Scoring Information
                    </h3>
                </div>

                <FormInput 
                    label='Name of your employer (Company)?'
                    name='employerName'
                    id="employerName"
                    type='text'
                    value={employerName}
                    setValue={setEmployerName}
                    required={true}
                />

                <FormSelect 
                    label='Kindly state the number of years you worked with your current employer?'
                    name='yearsAtWork'
                    id="yearsAtWork"
                    content={numOfYearsWithEmployer}
                    value={yearsAtWork}
                    setValue={setYearsAtWork}
                    required={true}
                />

                <FormSelect 
                    label='Which of the following categories of sector does your employment fall under?'
                    name='employmentSector'
                    id="employmentSector"
                    content={sectorOpts}
                    value={employmentSector}
                    setValue={setEmploymentSector}
                    required={true}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Credit Behaviour Information
                    </h3>
                </div>

                <FormSelect 
                    label='Do you currently have any outstanding debts or loans?'
                    name='outstandingLoans'
                    id="outstandingLoans"
                    content={optionsYN}
                    value={outstandingLoans}
                    setValue={setOutstandingLoans}
                    required={true}
                />

                <FormSelect 
                    label='Have you taken a loan from any financial institution?'
                    name='loansTaken'
                    id="loansTaken"
                    content={optionsYN}
                    value={loansTaken}
                    setValue={setLoansTaken}
                    required={true}
                />

                <FormSelect 
                    label='Have you ever defaulted on or missed a loan payment?'
                    name='loanDefaultStatus'
                    id="loanDefaultStatus"
                    content={optionsYN}
                    value={loanDefaultStatus}
                    setValue={setLoanDefaultStatus}
                    required={true}
                />

                <FormSelect 
                    label='How many times did you miss payment on your loan?'
                    name='defaultedLoanStatus'
                    id="defaultedLoanStatus"
                    content={missedPaymentOptions}
                    value={defaultedLoanStatus}
                    setValue={setDefaultedLoanStatus}
                    required={true}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Bills Payment Information
                    </h3>
                </div>

                <FormSelect 
                    label='What are the bills that you are responsible for paying?'
                    name='billsOption'
                    id="billsOption"
                    content={billPaymentOptions}
                    value={billsOption}
                    setValue={setBillsOption}
                    required={true}
                />

                <FormSelect 
                    label='What are the length of history of your bills payment history?'
                    name='billsHistory'
                    id="billsHistory"
                    content={billsHistoryOpts}
                    value={billsHistory}
                    setValue={setBillsHistory}
                    required={true}
                />

                <FormSelect 
                    label='How many times did you miss payment on your bills?'
                    name='billsHistoryStatus'
                    id="billsHistoryStatus"
                    content={missedPaymentOptions}
                    value={billsHistoryStatus}
                    setValue={setBillsHistoryStatus}
                    required={true}
                />

                <FormSelect 
                    label='Do you have 3 recent months loan payment history?'
                    name='loanPaymentHistory'
                    id="loanPaymentHistory"
                    content={optionsYN}
                    value={loanPaymentHistory}
                    setValue={setLoanPaymentHistory}
                    required={true}
                />

                <FormSelect 
                    label='Do you have 3 recent months bills payment history?'
                    name='billPaymentHistory'
                    id="billPaymentHistory"
                    content={optionsYN}
                    value={billPaymentHistory}
                    setValue={setBillPaymentHistory}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Savings Habit Information
                    </h3>
                </div>

                <div className="col-span-full">
                    <FormSelect 
                        label='Do you have a savings account held with a financial institution, pension or investment company that you can use for emergencies, such as sickness or job loss? Please note that regular savings and checking accounts may not meet this requirement.'
                        name='savingHabits'
                        id="savingHabits"
                        content={optionsYN}
                        value={savingHabits}
                        setValue={setSavingHabits}
                        required={true}
                    />
                </div>

                {savingHabits === "Yes" && (
                <>
                <FormInput 
                    label='Month 1'
                    name='savingsFreq1'
                    id="savingsFreq1"
                    type='number'
                    value={savingsFreq1}
                    setValue={setSavingsFreq1}
                />

                <FormInput 
                    label='Month 2'
                    name='savingsFreq2'
                    id="savingsFreq2"
                    type='number'
                    value={savingsFreq2}
                    setValue={setSavingsFreq2}
                />

                <FormInput 
                    label='Month 3'
                    name='savingsFreq3'
                    id="savingsFreq3"
                    type='number'
                    value={savingsFreq3}
                    setValue={setSavingsFreq3}
                />
                </>
                )}
                
                <FormSelect 
                    label='If you have appropriate insurance coverage, please indicate which of the following you have.'
                    name='typeOfInsurance'
                    id="typeOfInsurance"
                    content={insuranceOpts}
                    value={typeOfInsurance}
                    setValue={setTypeOfInsurance}
                    required={true}
                />
                
                <div className='col-span-full md:col-span-2'>
                    <FormSelect 
                        label='Do you regularly save a minimum of 5% of your monthly income for emergency purposes or future financial goals?'
                        name='eligibleSavingsHabit'
                        id="eligibleSavingsHabit"
                        content={optionsYN}
                        value={eligibleSavingsHabit}
                        setValue={setEligibleSavingsHabit}
                        required={true}
                    />
                </div>

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Loan Information
                    </h3>
                </div>

                <FormInput 
                    label='Loan amount'
                    name='amountReq'
                    id="amountReq"
                    type='number'
                    value={amountReq}
                    setValue={setAmountReq}
                    required={true}
                />

                <FormSelect 
                    label='What is the purpose of the loan?'
                    name='purpose'
                    id="purpose"
                    content={purposeOfLoanOpts}
                    value={purpose}
                    setValue={setPurpose}
                    required={true}
                />

                <FormSelect 
                    label='What is the desired payment plan?'
                    name='paymentPlan'
                    id="paymentPlan"
                    content={paymentPlanOpts}
                    value={paymentPlan}
                    setValue={setPaymentPlan}
                    required={true}
                />

                <FormSelect 
                    label='What is the desired payment duration?'
                    name='paymentDuration'
                    id="paymentDuration"
                    content={paymentDurationOpts}
                    value={paymentDuration}
                    setValue={setPaymentDuration}
                    required={true}
                />

                <FormInput 
                    label='Provide your source of income for loan repayment'
                    name='repaySource'
                    id="repaySource"
                    type='text'
                    value={repaySource}
                    setValue={setRepaySource}
                    required={true}
                />

                <FormSelect 
                    label='What is the frequency of your income?'
                    name='freqIncome'
                    id="freqIncome"
                    content={incomeFrequencyOpts}
                    value={freqIncome}
                    setValue={setFreqIncome}
                    required={true}
                />

                <FormSelect 
                    label='Select your preferred automatic repayment method'
                    name='autoDeduction'
                    id="autoDeduction"
                    content={automaticPaymentOpts}
                    value={autoDeduction}
                    setValue={setAutoDeduction}
                    required={true}
                />

                <div>
                    <label htmlFor="startDate" className='form-label'>
                        Select loan start date
                    </label>
                    <input 
                        type="date"
                        name={"startDate"}
                        id={"startDate"}
                        className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        min={new Date().toISOString().split('T')[0]}
                        max={nextMonthStartDate()}
                        required={true}
                    />
                </div>

                <FormSelect 
                    label='Kindly select a relationship manager'
                    name='relManager'
                    id="relManager"
                    content={relationshipManagers}
                    value={relManager}
                    setValue={setRelManager}
                    required={true}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Guarantor Information
                    </h3>
                </div>

                <FormInput 
                    label="Guarantor's first name"
                    name='guarantorFirstName'
                    id="guarantorFirstName"
                    type='text'
                    value={guarantorFirstName}
                    setValue={setGuarantorFirstName}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's last name"
                    name='guarantorLastName'
                    id="guarantorLastName"
                    type='text'
                    value={guarantorLastName}
                    setValue={setGuarantorLastName}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's phone number"
                    name='guarantorPhone'
                    id="guarantorPhone"
                    type='text'
                    value={guarantorPhone}
                    setValue={setGuarantorPhone}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's address"
                    name='guarantorAddress'
                    id="guarantorAddress"
                    type='text'
                    value={guarantorAddress}
                    setValue={setGuarantorAddress}
                    required={true}
                />

                <FormInput 
                    label="Your relationship to guarantor"
                    name='guarantorRelationship'
                    id="guarantorRelationship"
                    type='text'
                    value={guarantorRelationship}
                    setValue={setGuarantorRelationship}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's GhanaCard number"
                    name='guarantorIdNumber'
                    id="guarantorIdNumber"
                    type='text'
                    value={guarantorIdNumber}
                    setValue={setGuarantorIdNumber}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's digital address"
                    name='guarantorDigitalAddress'
                    id="guarantorDigitalAddress"
                    type='text'
                    value={guarantorDigitalAddress}
                    setValue={setGuarantorDigitalAddress}
                    required={true}
                />

                <div>
                    <label htmlFor="guarantorDob" className='form-label'>
                        Guarantor's date of birth
                    </label>
                    <input 
                        type="date"
                        name={"guarantorDob"}
                        id={"guarantorDob"}
                        className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                        value={guarantorDob}
                        onChange={e => setGuarantorDob(e.target.value)}
                        min={"1930-01-01"}
                        max={new Date().toISOString().split('T')[0]}
                        required={true}
                    />
                </div>

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Files to upload (Mandatory**)
                    </h3>
                </div>

                <FormUpload 
                    label='Upload proof of income'
                    name='incomeProof'
                    id="incomeProof"
                    setValue={setIncomeProof}
                    required={true}
                />

                <FormUpload 
                    label='Upload bank statement or momo statement'
                    name='bankOrMomoStatement'
                    id="bankOrMomoStatement"
                    setValue={setBankOrMomoStatement}
                    required={true}
                />

                <div className="col-span-full mt-10">
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Attach other files 
                    </h3>
                </div>

                <OtherDocsUpload 
                    setValue={setOtherDocs}
                />

                <div className='col-span-full mt-10 p-10 bg-sky-50 rounded-md text-slate-700'>
                    <h3 className='text-2xl font-medium leading-6 mb-6'>
                        Authorization 
                    </h3>

                    <h4 className='text-lg font-medium leading-6 mb-2'>
                        Loan Authorization
                    </h4>   

                    <p className='mb-6'>
                        I, hereby apply for loans stated in Sections of this application the information I have provided here is to the best of my knowledge true and correct. I authorize CedisPay Micro-Credit Enterprice to verify the correctness of this information and also to obtain additional information if it deems necessary in evaluating my loan application.
                    </p>

                    <h4 className='text-lg font-medium leading-6 mb-2'>
                        Disclosure to Credit Reference Bureaus
                    </h4>   

                    <p className='mb-3'>
                        CedisPay will obtain information about you from the credit reference bureaus to check your credit status and identity. The bureaus will record our enquiries which may be seen by other institutions that make their own credit enquiries about you. CedisPay shall also disclose your credit transactions to credit reference bureaus in accordance with the <strong>Credit Reporting Regulations, 2020 (L.I 2394)</strong>
                    </p>
                </div>

                <FormInput 
                    label='Signed Name'
                    name='signedName'
                    id="signedName"
                    type='text'
                    value={signedName}
                    disabled={true}
                />

                <div className="col-span-full mt-6">
                    <div className="inline-flex items-center gap-2">
                        <Button 
                            btnText={"Check eligibility"}
                            btnType={"submit"}
                            btnClasses={"application-button w-max"}
                            processing={processing}
                        />
                    </div>
                </div>

            </form>
        </div>

        <Modal isOpen={isOpen} closeModal={closeModal}>
            <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white dark:bg-dark-bg p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-white/[.7] flex items-center mb-4"
                >
                    Pre-Check Status: <span className={`ml-1 px-3 py-1.5 text-white font-medium rounded-full text-xs capitalize ${preCheckData?.status === 'accepted' ? 'bg-emerald-400' : preCheckData?.status === 'rejected' ? 'bg-red-400' : ''}`}>{preCheckData?.status}</span>
                </Dialog.Title>
                <EligCheck 
                    status={preCheckData?.status}
                    data={preCheckData}
                    loanType={"loanme"}
                    closeModal={closeModal}
                    setReviewModalOpen={setReviewModalOpen}
                />
            </Dialog.Panel>
        </Modal>

        <ReviewModal 
            isOpen={reviewModalOpen} 
            isClose={setReviewModalOpen}
            client={signedName}
        />
        </>
    )
}

export default LoanMe