import { roundNum } from './constants';
const { loanPricing, loanTypes, scoreToRatingMins, riskRates, interestRate, loanAmtLimits } = require('./loanConstants');


function getCreditRating(score){
    let scoreMins = Object.keys(scoreToRatingMins);
    let tmpScore, i =0;
    do {
        tmpScore = parseFloat(scoreMins[i]);
        i++;
    } while (tmpScore < score && i<=scoreMins.length+1);
    // i-2: i was incremented, and the tmpScore just greater is of 'i-1'
    let ret = scoreToRatingMins[scoreMins[i-2]]; //[tmpScore];
    return ret;
}

function getRiskRate(score){
    let rating = getCreditRating(score);
    let riskRate = riskRates[rating];
    return riskRate;
}

async function getCustomerRate(score, loanTypeIn, creditStatus){
    let loanType = loanTypeIn.toLowerCase();

    let loanPricingUsed = loanType === loanTypes.loanme ? {...loanPricing, ...loanPricing.loanme} :
    loanType === loanTypes.achieve ? {...loanPricing, ...loanPricing.achieve} :
    loanType === loanTypes.promo ? {...loanPricing, ...loanPricing.promo} :
    loanType === loanTypes.pension ? {...loanPricing, ...loanPricing.pension} :
    loanType === loanTypes.pencash ? {...loanPricing, ...loanPricing.pension} :
    loanType === loanTypes.invest ? {...loanPricing, ...loanPricing.invest} :
    loanType === loanTypes.insurance ? {...loanPricing, ...loanPricing.insurance} :
    loanType === loanTypes.employer ? {...loanPricing, ...loanPricing.employer} :
    loanType === loanTypes.ploc ? {...loanPricing, ...loanPricing.ploc} :
    loanType === loanTypes.salaried ? {...loanPricing, ...loanPricing.salaried} :
    {...loanPricing, ...loanPricing.capitalme};
    
    let custRiskPremium = getRiskRate(score);
    console.log(`customer risk premium: ${custRiskPremium}`);

    let yearlyRate = loanPricingUsed.margCostOfFund + loanPricingUsed.costToAsset + loanPricingUsed.expCreditLoss + loanPricingUsed.roe + custRiskPremium;
    let monthlyRate = yearlyRate / 12;
    monthlyRate = monthlyRate - loanPricingUsed.monthDiscount;
    monthlyRate = roundNum(monthlyRate, 2);
    console.log(`monthly rate: ${monthlyRate}`);

    monthlyRate = loanType === loanTypes.loanme ? Math.min(monthlyRate, interestRate.loanme) : 
        loanType === loanTypes.loanme && creditStatus === "Yes" ? Math.min(monthlyRate, interestRate.loanmeCredit) : 
        loanType === loanTypes.salaried ? Math.min(monthlyRate, interestRate.salaried) : 
        loanType === loanTypes.salaried && creditStatus === "Yes" ? Math.min(monthlyRate, interestRate.loanmeCredit) : 
        loanType === loanTypes.achieve ? Math.min(monthlyRate, interestRate.achieve) : 
        loanType === loanTypes.employer ? Math.min(monthlyRate, interestRate.employer) : 
        loanType === loanTypes.promo ? Math.min(monthlyRate, interestRate.promo) : 
        loanType === loanTypes.invest && creditStatus === "achieve" ? Math.min(monthlyRate, interestRate.investmentAchieve) : 
        loanType === loanTypes.invest && creditStatus === "petra" ? Math.min(monthlyRate, interestRate.investmentPetra) : 
        loanType === loanTypes.pencash ? Math.min(monthlyRate, interestRate.pensionCash) : 
        loanType === loanTypes.pension && creditStatus === "unsecured" ? Math.min(monthlyRate, interestRate.pensionUnsecured) :
        loanType === loanTypes.pension && creditStatus === "secured" ? Math.min(monthlyRate, interestRate.pension) : 
        loanType === loanTypes.pension && creditStatus === "Yes" ? Math.min(monthlyRate, interestRate.pensionCredit) : 
        loanType === loanTypes.ploc ? Math.min(monthlyRate, interestRate.ploc) : 
        loanType === loanTypes.capitalme && creditStatus === "topup" ? Math.min(monthlyRate, interestRate.capitalmeTopUp) :
        Math.min(monthlyRate, interestRate.capitalme);

    console.log(`monthly rate: ${monthlyRate}, yearly rate: ${yearlyRate}`);

    return monthlyRate;
}

function pv(rate, nper, pmt, fv) {
    rate = parseFloat(rate);
    nper = parseFloat(nper);
    pmt = parseFloat(pmt);
    fv = parseFloat(fv);
    // debugLog(`parsedFloats: ${rate}, ${nper}, ${pmt}, ${fv}`);
    if ( nper === 0 ) {
        // alert("Why do you want to test me with zeros?");
        return(0);       
    }
    let pv_value;
    if ( rate === 0 ) { // Interest rate is 0
        pv_value = -(fv + (pmt * nper));
    } else {
        let x = Math.pow(1 + rate, -nper); 
        let y = Math.pow(1 + rate, nper);
        console.log(`x,y: ${x}, ${y}`);
        pv_value = - ( x * ( fv * rate - pmt + y * pmt )) / rate;
    }
    pv_value = roundNum(pv_value,2);
    return (pv_value);
}

// loanMe eligibility calculator
async function getLoanMeAmountEligible(rateMonth, numMonths, clientFinances, creditScore, insurScore, momoScore, savingsScore, employedCurrently){
    console.log("rate: ", rateMonth);
    console.log("duration: ", numMonths);
    console.log("income: ", clientFinances);
    console.log("credit habit: ", creditScore);
    console.log("insurance habit: ", insurScore);
    console.log("momo habit: ", momoScore);
    console.log("savings habit: ", savingsScore);
    console.log("employed for at least 1yr: ", employedCurrently);
    // new formular
    let amountCanPayMonth = clientFinances?.savings;
    let presentValue = -pv(rateMonth/100, numMonths, amountCanPayMonth, 0);
    console.log("income: ", amountCanPayMonth)
    console.log("present value: ", presentValue)

    let eligFactorMomo = (momoScore === "No") ? 1 : 0;
    let eligFactorInsur = (insurScore === "No") ? 0.2 : 0;
    let eligSavings = (savingsScore === "No") ? 0.4 : 0;
    let eligEmployedCurrently = (employedCurrently === "No") ? 0.2 : 0;
    
    let eligFactor = (creditScore >= 91 && creditScore <= 100) ? 0 : (creditScore >= 81 && creditScore < 91) ? 0.2 : (creditScore >= 71 && creditScore < 81) ? 0.3 : (creditScore >= 61 && creditScore < 71) ? 0.5 : (creditScore >= 51 && creditScore < 61) ? 1 : 0;  

    let incomeHairCut = Number((30 / 100) * presentValue);
    let employedHaircut = Number((presentValue - incomeHairCut) * eligEmployedCurrently);
    let momoValue = Number((presentValue - incomeHairCut - employedHaircut) * eligFactorMomo);
    let insurValue = Number((presentValue - incomeHairCut - momoValue) * eligFactorInsur);
    let savingsValue = Number((presentValue - incomeHairCut - momoValue - insurValue) * eligSavings);
    let creditValue = Number((presentValue - incomeHairCut - momoValue - insurValue - savingsValue) * eligFactor);

    let totalHairCuts = Number(incomeHairCut + employedHaircut + momoValue + insurValue + savingsValue + creditValue);

    let loanElig = Number(presentValue - totalHairCuts);
    loanElig = loanElig.toFixed(2);
    if(loanElig < 0){
        loanElig = 0
    }
    return Math.round(loanElig)
}

// Pension Loan Eligibility (CedisCredit variant) 
async function getPensionCreditEligibility(rateMonth, numMonths, income, debt, balance, type, score, loanAmt) {
    console.log("triggered pension cedisCredit eligibility");
    console.log("rate: ", rateMonth);
    console.log("duration: ", numMonths);
    console.log("income: ", income);
    console.log("debt: ", debt);
    console.log("balance on type of collateral: ", balance);
    console.log("type of collateral: ", type);
    console.log("credit score: ", score);
    console.log("loan amount: ", loanAmt);

    let netIncome = income - debt;
    console.log("net income: ", netIncome);

    let eligAmtBegin = roundNum(type === "Investments" ? balance * 0.8 : balance * 0.8);
    console.log("balance amount: ", eligAmtBegin);

    let multiplier = (numMonths > 6 && numMonths <= 12) ? netIncome * 3.5 : (numMonths >= 3 && numMonths <= 6) ? netIncome * 2 : 0;
    console.log("initial eligible amount: ", multiplier);

    let creditScore = (score >= 81 && score <= 100) ? (0 / 100) : 
                        (score >= 71 && score <= 80) ? (20 / 100) :
                        (score >= 61 & score <= 70) ? (30 / 100) :
                        (score >= 51 & score <= 60) ? (50 / 100) :
                        (score >= 0 & score <= 50) ? (100 / 100) : 0;
    creditScore = creditScore * multiplier;

    let totalHaircuts = creditScore;

    let loanBasedOnIncome = multiplier - totalHaircuts;
    console.log("loan based on income: ", loanBasedOnIncome);

    let maxAmt = Math.min(Math.min(loanBasedOnIncome, eligAmtBegin), loanAmtLimits.pension);

    let eligAmtFinal = Math.min(maxAmt, loanAmt);
    console.log("final eligible amount (after limiter is applied): ", eligAmtFinal);
    
    let loanElig = eligAmtFinal.toFixed(2);
    if(loanElig < 0){
        loanElig = 0
    }

    return Math.round(loanElig)
    
}


// Pension Loan Eligibility
async function getPensionSecuredEligibility(rateMonth, numMonths, income, debt, balance, type, score, loanAmt, loanKind) {
    console.log("triggered pension eligibility");
    console.log("rate: ", rateMonth);
    console.log("duration: ", numMonths);
    console.log("income: ", income);
    console.log("debt: ", debt);
    console.log("balance on type of collateral: ", balance);
    console.log("type of collateral: ", type);
    console.log("credit score: ", score);
    console.log("loan amount requested: ", loanAmt);
    console.log("loan kind: ", loanKind);

    let netIncome = income - debt;
    console.log("net income: ", netIncome);

    let eligAmtBegin = loanKind === "Pension Promo" ? roundNum(type === "Investments" ? balance * 0.8 : balance * 1.5) : roundNum(type === "Investments" ? balance * 0.7 : balance * 0.8);
    console.log("balance amount: ", eligAmtBegin);

    let multiplier = (numMonths > 6 && numMonths <= 12) ? netIncome * 3.5 : (numMonths >= 3 && numMonths <= 6) ? netIncome * 1.5 : 0;
    console.log("initial eligible amount: ", multiplier);

    let creditScore = (score >= 81 && score <= 100) ? (0 / 100) : 
                        (score >= 71 && score <= 80) ? (20 / 100) :
                        (score >= 61 & score <= 70) ? (30 / 100) :
                        (score >= 51 & score <= 60) ? (50 / 100) :
                        (score >= 0 & score <= 50) ? (100 / 100) : 0;
    creditScore = creditScore * multiplier;

    let totalHaircuts = creditScore;

    let loanBasedOnIncome = multiplier - totalHaircuts;
    console.log("loan based on income: ", loanBasedOnIncome);

    let maxAmt = Math.min(Math.min(loanBasedOnIncome, eligAmtBegin), loanAmtLimits.pensionLoanLimit);

    let eligAmtFinal = Math.min(maxAmt, loanAmt);
    console.log("final eligible amount (after limiter is applied): ", eligAmtFinal);
    
    let loanElig = eligAmtFinal.toFixed(2);
    if(loanElig < 0){
        loanElig = 0
    } 

    return Math.round(loanElig)
    
}


// Pension Cash Eligibility
async function getPenCashEligibility(rateMonth, numMonths, clientFinances, creditScore, insurScore, momoScore, savingsScore, employedCurrently) {
    console.log("rate: ", rateMonth);
    console.log("duration: ", numMonths);
    console.log("income: ", clientFinances);
    console.log("credit habit: ", creditScore);
    console.log("insurance habit: ", insurScore);
    console.log("momo habit: ", momoScore);
    console.log("savings habit: ", savingsScore);
    console.log("employed for at least 1yr: ", employedCurrently);
    // new formular
    let amountCanPayMonth = clientFinances?.savings;
    let presentValue = -pv(rateMonth/100, numMonths, amountCanPayMonth, 0);
    console.log("income: ", amountCanPayMonth)
    console.log("present value: ", presentValue)

    let eligFactorMomo = (momoScore === "No") ? 0.2 : 0;
    let eligFactorInsur = (insurScore === "No") ? 0.1 : 0;
    let eligSavings = (savingsScore === "No") ? 0.4 : 0;
    let eligEmployedCurrently = (employedCurrently === "No") ? 0.1 : 0;
    
    let eligFactor = (creditScore >= 91 && creditScore <= 100) ? 0 : (creditScore >= 81 && creditScore < 91) ? 0.2 : (creditScore >= 71 && creditScore < 81) ? 0.3 : (creditScore >= 50 && creditScore < 71) ? 0.5 : 0;  

    let incomeHairCut = parseFloat((30 / 100) * presentValue);
    let employedHaircut = parseFloat((presentValue - incomeHairCut) * eligEmployedCurrently);
    let momoValue = parseFloat((presentValue - incomeHairCut - employedHaircut) * eligFactorMomo);
    let insurValue = parseFloat((presentValue - incomeHairCut - momoValue) * eligFactorInsur);
    let savingsValue = parseFloat((presentValue - incomeHairCut - momoValue - insurValue) * eligSavings);
    let creditValue = parseFloat((presentValue - incomeHairCut - momoValue - insurValue - savingsValue) * eligFactor);

    let totalHairCuts = parseFloat(incomeHairCut + employedHaircut + momoValue + insurValue + savingsValue + creditValue);

    let loanElig = parseFloat(presentValue - totalHairCuts);
    loanElig = loanElig.toFixed(2);
    if(loanElig < 0){
        loanElig = 0
    } 
    
    return Math.round(loanElig)
    
}


// CapitalMe CedisCredit Eligibility
async function getCapitalMeCreditEligibility(rateMonth, numMonths, income, debt, loanAmt) {
    console.log("triggered capitalme cedisCredit eligibility");
    console.log("rate: ", rateMonth);
    console.log("duration: ", numMonths);
    console.log("income: ", income);

    // estimate expenses (55%) of income
    let estimatedExpenses = Math.round((55 / 100) * income);

    let netIncome = income - estimatedExpenses - debt;
    console.log("net income: ", netIncome);

    let multiplier = (numMonths > 6 && numMonths <= 12) ? netIncome * 3.5 : (numMonths >= 3 && numMonths <= 6) ? netIncome * 2 : 0;
    console.log("initial eligible amount: ", multiplier);

    let loanBasedOnIncome = multiplier;
    console.log("loan based on income: ", loanBasedOnIncome);

    let maxAmt = Math.min(loanBasedOnIncome, loanAmtLimits.businessLoanCreditLimit);

    let eligAmtFinal = Math.min(maxAmt, loanAmt);
    console.log("final eligible amount (after limiter is applied): ", eligAmtFinal);
    

    let loanElig = eligAmtFinal.toFixed(2);
    if(loanElig < 0){
        loanElig = 0
    } 

    return Math.round(loanElig)
    
}

// CapitalMe (Normal) Eligibility
async function getCapitalMeAmountEligibility(rateMonth, numMonths, clientFinances, creditScore, insurScore, savings, numOfTransctions, employedCurrently) {
    console.log("rate: ", rateMonth);
    console.log("duration: ", numMonths);
    console.log("income: ", clientFinances);
    console.log("credit habit: ", creditScore);
    console.log("insurance habit: ", insurScore);
    console.log("savings habit: ", savings);
    console.log("automated loan payments: ", numOfTransctions);
    console.log("employed for at least 1yr: ", employedCurrently);
    // new formular
    let amountCanPayMonth = clientFinances.savings;
    let presentValue = -pv(rateMonth / 100, numMonths, amountCanPayMonth, 0);
    console.log("income: ", amountCanPayMonth)
    console.log("present value: ", presentValue)

    // let eligFactorMomo = (momoScore === 50) ? 0.5 : 0;
    let eligFactorInsur = (insurScore === 50) ? 0.2 : 0;
    let eligFactorSavings = (savings === "Yes") ? 0 : 0.5;
    let eligFactorBusiness = (numOfTransctions === "Yes") ? 0 : 1;
    let eligEmployedCurrently = (employedCurrently === "Yes") ? 0 : 0.2;
    
    let eligFactor = (creditScore >= 91 && creditScore <= 100) ? 0 : (creditScore >= 81 && creditScore < 91) ? 0.2 : (creditScore >= 71 && creditScore < 81) ? 0.3 : (creditScore >= 61 && creditScore < 71) ? 0.5 : (creditScore >= 51 && creditScore < 61) ? 1 : 0;  

    let incomeHairCut = parseFloat((30 / 100) * presentValue);
    let employedHaircut = parseFloat((presentValue - incomeHairCut) * eligEmployedCurrently);
    let busValue = parseFloat((presentValue - incomeHairCut - employedHaircut) * eligFactorBusiness);
    // let momoValue = parseFloat((presentValue - incomeHairCut) * eligFactorMomo);
    let insurValue = parseFloat((presentValue - incomeHairCut- busValue) * eligFactorInsur);
    let savValue = parseFloat((presentValue - incomeHairCut - busValue - insurValue) * eligFactorSavings);
    let creditValue = parseFloat((presentValue - incomeHairCut - busValue - insurValue - savValue) * eligFactor);

    let totalHairCuts = parseFloat(incomeHairCut + employedHaircut + insurValue + savValue + busValue + creditValue);

    let loanElig = parseFloat(presentValue - totalHairCuts);
    loanElig = loanElig.toFixed(2);
    if(loanElig < 0){
        loanElig = 0
    } 

    return Math.round(loanElig)
}


// CedisCredit Eligibility
async function getCedisCreditEligibility(income, debt, dti, score){
    console.log("Monthly income: ", income);
    console.log("Monthly debt: ", debt);
    console.log("Debt to income ratio: ", dti);
    console.log("credit score: ", score);

    let netIncome = parseFloat(income) - parseFloat(debt);
    console.log("Net income: ", netIncome);

    let maximumAmtNet = netIncome <= 2000 ? netIncome : (netIncome > 2000 && netIncome <= 5000) ? netIncome * 1.5 : netIncome * 2;
    console.log("maximum amount net income: ", maximumAmtNet);

    let eligAmt = Math.max(((score > 50 && dti <= 90) ? maximumAmtNet * (40 / 100) : 0), 0);
    console.log("eligible amount: ", eligAmt);

    return eligAmt;

}


// CashMe Eligibility
async function getCashMeEligibility(rate, income, duration){
    console.log(`rate : ${rate}`);
    console.log(`income : ${income}`);
    console.log(`duration : ${duration}`);

    let interestRate = (parseFloat(rate) / 100);

    let presentValue = (-pv(interestRate, duration, income, 0)).toFixed(2);

    return presentValue

}


// Investment Eligibility
async function getInvestmentEligibility(rateMonth, numMonths, income, debt, balance, type, score){
    console.log("rate: ", rateMonth);
    console.log("duration: ", numMonths);
    console.log("income: ", income);
    console.log("debt: ", debt);
    console.log("balance on type of collateral: ", balance);
    console.log("type of collateral: ", type);
    console.log("credit score: ", score);

    let netIncome = income - debt;
    console.log("net income: ", netIncome);

    let eligAmtBegin = roundNum(type === "Investments" ? balance * 0.7 : balance * 0.8);
    console.log("balance amount: ", eligAmtBegin);

    let multiplier = (numMonths > 6 && numMonths <= 12) ? netIncome * 3.5 : (numMonths >= 3 && numMonths <= 6) ? netIncome * 1.5 : 0;
    console.log("initial eligible amount: ", multiplier);

    let creditScore = (score >= 81 && score <= 100) ? (0 / 100) : 
                        (score >= 71 && score <= 80) ? (20 / 100) :
                        (score >= 61 & score <= 70) ? (30 / 100) :
                        (score >= 51 & score <= 60) ? (50 / 100) :
                        (score >= 0 & score <= 50) ? (100 / 100) : 0;
    creditScore = creditScore * multiplier;

    let totalHaircuts = creditScore;

    let loanBasedOnIncome = multiplier - totalHaircuts;
    console.log("loan based on income: ", loanBasedOnIncome);

    let eligAmtFinal = Math.min(Math.min(loanBasedOnIncome, eligAmtBegin), loanAmtLimits.investCashLoanLimit);
    console.log("final eligible amount (after limiter is applied): ", eligAmtFinal);

    let loanElig = eligAmtFinal.toFixed(2);
    if(loanElig < 0){
        loanElig = 0
    }

    return Math.round(loanElig)
    
}


// Payroll Loan Eligibility
async function getPayrollEligibility(rateMonth, numMonths, income, debt, score, loanAmt){
    console.log("rate: ", rateMonth);
    console.log("duration: ", numMonths);
    console.log("income: ", income);
    console.log("debt: ", debt);
    console.log("credit score: ", score);
    console.log("amount requested: ", loanAmt);

    let netIncome = income - debt;
    console.log("net income: ", netIncome);

    let multiplier = (numMonths > 6 && numMonths <= 12) ? netIncome * 3 : (numMonths >= 3 && numMonths <= 6) ? netIncome * 1.5 : 0;
    console.log("initial eligible amount: ", multiplier);
    
    // let totalHaircuts = creditScore;
    let totalHaircuts = 0;
    console.log("Total Haircut: ", totalHaircuts);

    let loanBasedOnIncome = multiplier - totalHaircuts;
    console.log("Maximum amount of the loan: ", loanBasedOnIncome);

    let eligAmtFinal = Math.min(loanBasedOnIncome, loanAmt);
    console.log("final eligible amount (after limiter is applied): ", eligAmtFinal);
    
    let loanElig = eligAmtFinal.toFixed(2);
   
    if(loanElig < 0){
        loanElig = 0
    } 
    
    return Math.round(loanElig)

}


export {
    getCustomerRate,

    // eligibility calculators
    getLoanMeAmountEligible, // also being used for pension unsecured loan submissions
    getPensionCreditEligibility,
    getPensionSecuredEligibility,
    getPenCashEligibility,
    getCapitalMeCreditEligibility,
    getCapitalMeAmountEligibility,
    getCedisCreditEligibility,
    getCashMeEligibility,
    getInvestmentEligibility,
    getPayrollEligibility
}