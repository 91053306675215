import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { FormInput, FormSelect, FormUpload, Button, Modal, EligCheck } from "components"
import { purposeOfLoanOpts, paymentPlanOpts, relationshipManagers, networkOptions, maxFileSize5 } from 'components/misc/constants';
import { preCheckLoanEligibility } from '../../misc/loanPreChecks';
import LoanRequirement from './LoanRequirement';
import ReviewModal from 'components/Utils/ReviewModal';
import { useLocation } from 'hooks';
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';

function Credit({ client }) {
    const { city, country, region } = useLocation();
    const dispatch = useDispatch();
    
    const fullName = client?.otherNames + " " + client?.surname;

    let [isOpen, setIsOpen] = useState(false);
    const [isLoanReqOpen, setIsLoanReqOpen] = useState(false);
    const [reviewModalOpen, setReviewModalOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false)
    }
    
    const openModal = () => {
        setIsOpen(true)
    }

    const [purpose, setPurpose] = useState('');
    const [paymentPlan, setPaymentPlan] = useState('');
    const [preferredNetwork, setPreferredNetwork] = useState('');
    const [residenceProof, setResidenceProof] = useState('');
    const [bankOrMomoStatement, setBankOrMomoStatement] = useState('');
    const [relManager, setRelManager] = useState('');
    const [guarantorFirstName, setGuarantorFirstName] = useState('');
    const [guarantorLastName, setGuarantorLastName] = useState('');
    const [guarantorPhone, setGuarantorPhone] = useState('');
    const [guarantorAddress, setGuarantorAddress] = useState('');
    const [guarantorRelationship, setGuarantorRelationship] = useState('');
    const guarantorIdType = 'GhanaCard';
    const [guarantorIdNumber, setGuarantorIdNumber] = useState('');
    const [guarantorDigitalAddress, setGuarantorDigitalAddress] = useState('');
    const [guarantorDob, setGuarantorDob] = useState('');
    const signedName = fullName;
    const [preCheckData, setPreCheckData] = useState({}); 
    const [processing, setProcessing] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);

        // check to see if files have been uploaded
        let bankFile = document.getElementById("bankOrMomoStatement");

        if(!bankFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach momo statement"
            }))
            return;
        }

        if(bankFile.files[0].size > maxFileSize5){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "File size too large. Maximum file size is 5MB"
            }))
            return;
        }

        let data = {
            purpose, paymentPlan, autoDeduction : "Mobile Money Auto-Deduction", preferredNetwork, residenceProof, bankOrMomoStatement, relManager, guarantorFirstName, guarantorLastName, guarantorPhone, guarantorAddress, guarantorRelationship, guarantorIdType, guarantorIdNumber, guarantorDigitalAddress, guarantorDob, signedName, city, region, country
        };

        // precheck loan application eligibility
        let response = await preCheckLoanEligibility("cediscredit", data);
        setPreCheckData({ ...response, data });

        setTimeout(() => {
            setProcessing(false);
            openModal();
        }, 3000);
    }

    useEffect(() => {
        // open loan requirements modal
        setIsLoanReqOpen(true);
    },[]);


    return (
        <>
        <LoanRequirement 
            isOpen={isLoanReqOpen}
            setIsLoanReqOpen={setIsLoanReqOpen}
            type="CedisCredit"
            requirements={[
                "6 Months Mobile Money Statement",
            ]}
        />

        <div className='display_card'>
            <form className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-2' onSubmit={handleRequest}>
                <div className='col-span-full'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Loan Information
                    </h3>
                </div>

                <FormSelect 
                    label='What is the purpose of the loan?'
                    name='purpose'
                    id="purpose"
                    content={purposeOfLoanOpts}
                    value={purpose}
                    setValue={setPurpose}
                    required={true}
                />

                <FormSelect 
                    label='What is the desired payment plan?'
                    name='paymentPlan'
                    id="paymentPlan"
                    content={paymentPlanOpts.slice(1, 4)}
                    value={paymentPlan}
                    setValue={setPaymentPlan}
                    required={true}
                />

                <FormSelect
                    label="Please select your phone network"
                    name="preferredNetwork"
                    id="preferredNetwork"
                    content={networkOptions.slice(0, 2)}
                    value={preferredNetwork}
                    setValue={setPreferredNetwork}
                    required={true}
                />

                <FormSelect 
                    label='Kindly select a relationship manager'
                    name='relManager'
                    id="relManager"
                    content={relationshipManagers}
                    value={relManager}
                    setValue={setRelManager}
                    required={true}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Guarantor Information
                    </h3>
                </div>

                <FormInput 
                    label="Guarantor's first name"
                    name='guarantorFirstName'
                    id="guarantorFirstName"
                    type='text'
                    value={guarantorFirstName}
                    setValue={setGuarantorFirstName}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's last name"
                    name='guarantorLastName'
                    id="guarantorLastName"
                    type='text'
                    value={guarantorLastName}
                    setValue={setGuarantorLastName}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's phone number"
                    name='guarantorPhone'
                    id="guarantorPhone"
                    type='text'
                    value={guarantorPhone}
                    setValue={setGuarantorPhone}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's address"
                    name='guarantorAddress'
                    id="guarantorAddress"
                    type='text'
                    value={guarantorAddress}
                    setValue={setGuarantorAddress}
                    required={true}
                />

                <FormInput 
                    label="Your relationship to guarantor"
                    name='guarantorRelationship'
                    id="guarantorRelationship"
                    type='text'
                    value={guarantorRelationship}
                    setValue={setGuarantorRelationship}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's GhanaCard number"
                    name='guarantorIdNumber'
                    id="guarantorIdNumber"
                    type='text'
                    value={guarantorIdNumber}
                    setValue={setGuarantorIdNumber}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's digital address"
                    name='guarantorDigitalAddress'
                    id="guarantorDigitalAddress"
                    type='text'
                    value={guarantorDigitalAddress}
                    setValue={setGuarantorDigitalAddress}
                    required={true}
                />

                <div>
                    <label htmlFor="guarantorDob" className='form-label'>
                        Guarantor's date of birth
                    </label>
                    <input 
                        type="date"
                        name={"guarantorDob"}
                        id={"guarantorDob"}
                        className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                        value={guarantorDob}
                        onChange={e => setGuarantorDob(e.target.value)}
                        min={"1930-01-01"}
                        max={new Date().toISOString().split('T')[0]}
                        required={true}
                    />
                </div>

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Files to upload
                    </h3>
                </div>

                <FormUpload 
                    label='Upload bank statement or momo statement'
                    name='bankOrMomoStatement'
                    id="bankOrMomoStatement"
                    value={bankOrMomoStatement}
                    setValue={setBankOrMomoStatement}
                    required={true}
                />

                <FormUpload 
                    label='Upload proof of residence'
                    name='residenceProof'
                    id="residenceProof"
                    value={residenceProof}
                    setValue={setResidenceProof}
                    required={false}
                />

                <div className='col-span-full mt-10 p-10 bg-sky-50 rounded-md text-slate-700'>
                    <h3 className='text-2xl font-medium leading-6 mb-6'>
                        Authorization 
                    </h3>

                    <h4 className='text-lg font-medium leading-6 mb-2'>
                        Loan Authorization
                    </h4>   

                    <p className='mb-6'>
                        I, hereby apply for loans stated in Sections of this application the information I have provided here is to the best of my knowledge true and correct. I authorize CedisPay Micro-Credit Enterprice to verify the correctness of this information and also to obtain additional information if it deems necessary in evaluating my loan application.
                    </p>

                    <h4 className='text-lg font-medium leading-6 mb-2'>
                        Disclosure to Credit Reference Bureaus
                    </h4>   

                    <p className='mb-3'>
                        CedisPay will obtain information about you from the credit reference bureaus to check your credit status and identity. The bureaus will record our enquiries which may be seen by other institutions that make their own credit enquiries about you. CedisPay shall also disclose your credit transactions to credit reference bureaus in accordance with the <strong>Credit Reporting Regulations, 2020 (L.I 2394)</strong>
                    </p>
                </div>

                <FormInput 
                    label='Signed Name'
                    name='signedName'
                    id="signedName"
                    type='text'
                    value={signedName}
                    disabled={true}
                />

                <div className="col-span-full mt-6">
                    <div className="inline-flex items-center gap-2">
                        <Button 
                            btnText={"Check eligibility"}
                            btnType={"submit"}
                            btnClasses={"application-button w-max"}
                            processing={processing}
                        />
                    </div>
                </div>

            </form>
        </div>

        <Modal isOpen={isOpen} closeModal={closeModal}>
            <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white dark:bg-dark-bg p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-white/[.7] flex items-center mb-4"
                >
                    Pre-Check Status: <span className={`ml-1 px-3 py-1.5 text-white font-medium rounded-full text-xs capitalize ${preCheckData?.status === 'accepted' ? 'bg-emerald-400' : preCheckData?.status === 'rejected' ? 'bg-red-400' : ''}`}>{preCheckData?.status}</span>
                </Dialog.Title>
                <EligCheck 
                    status={preCheckData?.status}
                    data={preCheckData}
                    loanType={"cediscredit"}
                    closeModal={closeModal}
                    setReviewModalOpen={setReviewModalOpen}
                />
            </Dialog.Panel>
        </Modal>

        <ReviewModal 
            isOpen={reviewModalOpen} 
            isClose={setReviewModalOpen}
            client={signedName}
        />

        </>
    )
}

export default Credit