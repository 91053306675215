import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { FormTextarea, Button } from 'components';
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
import { fetchRequests } from 'https/requests';
import { useUser } from 'hooks';
import { useNavigate } from 'react-router-dom';

function ReviewModal({ isOpen, isClose, client }) {

    const navigate = useNavigate();
    const { accessToken } = useUser();
    const dispatch = useDispatch();
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [submitting, setSubmitting] = useState(false);

    function closeModal() {
        isClose(false);

        setTimeout(() => {
            navigate("/");
        }, 1000);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setSubmitting(true);
        
        // Submit the feedback to the server
        if(rating === 0) {
            setSubmitting(false);
            dispatch(show({
                state : true,
                message : `Please give us a rating before submitting your feedback.`,
                type : "error"
            }))
            return;
        }

        let url = `${process.env.REACT_APP_STAGING_AUTH}/feedback`;

        let data = {
            client,
            rating,
            feedback
        }

        let headers = {
            "Content-Type" : "application/json",
            "Authorization" : `${accessToken}`
        }

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setSubmitting(false);

        if(res.code === 401) {
			navigate("/login");
            dispatch(show({
                state : true,
                message : `Your session has expired, please login to continue`,
                type : "token"
            }))
            return;
        }

        if(res.err) {
            dispatch(show({
                state : true,
                message : res.err,
                type : "error"
            }))
            return;
        }

        dispatch(show({
            state : true,
            message : res.message,
            type : "success"
        }))

        setTimeout(() => {
            closeModal();
        }, 1200);

        setTimeout(() => {
            navigate("/");
        }, 1300);

    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                    <Dialog.Panel className="w-full max-w-md md:max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-dark-page p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white/[.7] mb-4">
                        Give us your feedback
                    </Dialog.Title>
                    <div className="mt-2">
                        <form className="space-y-4">
                            <div className="flex items-center">
                                <label className="mr-4">Rating:</label>
                                <div className="flex space-x-1">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <button
                                            key={star}
                                            type="button"
                                            className="text-gray-400 hover:text-yellow-500 focus:outline-none"
                                            onClick={() => setRating(star)}
                                        >
                                            <svg className={`w-6 h-6 ${rating >= star ? 'text-yellow-500' : 'text-gray-400'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.957a1 1 0 00.95.69h4.162c.969 0 1.371 1.24.588 1.81l-3.37 2.448a1 1 0 00-.364 1.118l1.286 3.957c.3.921-.755 1.688-1.54 1.118l-3.37-2.448a1 1 0 00-1.175 0l-3.37 2.448c-.784.57-1.838-.197-1.54-1.118l1.286-3.957a1 1 0 00-.364-1.118L2.05 9.384c-.783-.57-.38-1.81.588-1.81h4.162a1 1 0 00.95-.69l1.286-3.957z" />
                                            </svg>
                                        </button>
                                    ))}
                                </div>
                            </div>

                            <FormTextarea 
                                label={"Your Feedback"}
                                name={"feedback"}
                                id={"feedback"}
                                rows={4}
                                placeholder={"Write your feedback here..."}
                                disabled={false}
                                value={feedback}
                                setValue={setFeedback}
                                required={true}
                            />

                            <div className="flex justify-end space-x-4">
                                <Button 
                                    btnText={"Submit"}
                                    btnType={"button"}
                                    btnClasses={"inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"}
                                    btnFunction={handleSubmit}
                                    processing={submitting}
                                />

                                <Button 
                                    btnType={"button"}
                                    btnText={"Cancel"}
                                    btnClasses={"form-button-cancel"}
                                    btnFunction={closeModal}
                                />
                            </div>
                        </form>
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    )
}

export default ReviewModal