import { roundNum } from "../constants";
import { workYears } from "../loanConstants";

//  ---- SCORING CARDS AND WEIGHTS -----  //
// 1. scoring dti
const scoringDTIStatus = {
    below35 : 30,
    upTo43 : 20,
    upTo50 : 15,
    above51 : 5
}
const scoringDTIDenom = Math.max(...Object.values(scoringDTIStatus));
const scoringDTIMaxWgt = 15;

// 2. average momo balance
const averageBalValues = {
    above1k : 25,
    max1k : 20,
    max750 : 15,
    max500 : 10,
    below250 : 5
}
const averageBalMax = Math.max(...Object.values(averageBalValues));
const averageBalValuesMaxWgt = 15;

// 3. automated loan payment
const automatedPaymentsObj = {
    Yes : 30,
    No : 0
}
const automatedDenom = Math.max(...Object.values(automatedPaymentsObj));
const automatedPaymentsMaxWgt = 15;

// 4. average momo transactions per month
const averageMomoPerMonth = {
    30 : 30,
    20 : 20,
    15 : 15,
    5 : 5
}
const averageMomoDenom = Math.max(...Object.values(averageMomoPerMonth));
const averageMomoMaxWgt = 8;

// 5. minimun amount per transaction
const minAmtPerMonth = {
    30 : 30,
    20 : 20,
    15 : 15,
    5 : 5
}
const minAmtPerMonthDenom = Math.max(...Object.values(minAmtPerMonth));
const minAmtMaxWgt = 8;

// 6. income source consistency
const incomeConsist = {
    40 : 40,
    30 : 30,
    20 : 20,
    10 : 10
}
const incomeConsistDenom = Math.max(...Object.values(incomeConsist));
const incomeConsistMaxWgt = 8;

// 7. number of years with current employer / current business location
const workYearsDenom = Math.max(...Object.values(workYears))
const workYearsMaxWgt = 8;

// 8. recent 3 months loan payment
const loanPaymentHstObj = {
    Yes : 30,
    No : 0
}
const loanPaymentMax = Math.max(...Object.values(loanPaymentHstObj));
const loanPaymentMaxWgt = 8;

// 9. recent 3 months bills payment
const billsPaymentHstObj = {
    Yes : 30,
    No : 0
}
const billsPaymentMax = Math.max(...Object.values(billsPaymentHstObj));
const billsPaymentMaxWgt = 5;

// 10. credit status loan payment
const cedisCreditStatus = {
    noMiss : 30,
    max1 : 20,
    max2 : 5,
    missedMore : 0,
    noCred : 0
}
const loanCreditStatusMax = Math.max(...Object.values(cedisCreditStatus));
const loanCreditStatusMaxWgt = 5;

// 11. credit status bills payment
const billsCreditStatusMax = Math.max(...Object.values(cedisCreditStatus));
const billsCreditStatusMaxWgt = 5;


async function getCedisCreditScore(scoringDTI, averageMomoBalance, automatedPayments, numTransPerMonth, minAmtPM, incConsist, yearsAtWork, loanPaymentHistory, billPaymentHistory, loanHistoryStatus, billsHistoryStatus){

    // 1. Debt to income ratio
    let scoringDTIRaw = scoringDTI <= 35 ? scoringDTIStatus.below35 : 
                        scoringDTI >= 36 && scoringDTI <= 43 ? scoringDTIStatus.upTo43 : 
                        scoringDTI >= 44 && scoringDTI <= 50 ? scoringDTIStatus.upTo50 : 
                        scoringDTIStatus.above51;
    let scoringDTIScore = (scoringDTIRaw / scoringDTIDenom) * scoringDTIMaxWgt;
    scoringDTIScore = roundNum(scoringDTIScore, 2);

    // 2. average 3 months mobile money balance
    let averageMomoBalanceRaw = averageBalValues[averageMomoBalance];
    let averageMomoBalanceScore = (averageMomoBalanceRaw / averageBalMax) * averageBalValuesMaxWgt;
    averageMomoBalanceScore = roundNum(averageMomoBalanceScore, 2);
    console.log(`average 3 months mobile money balance: ${averageMomoBalanceScore}`);

    // 3. automated loan payments
    let automatedPaymentsRaw = automatedPaymentsObj[automatedPayments];
    let automatedPaymentsScore = (automatedPaymentsRaw / automatedDenom) * automatedPaymentsMaxWgt;
    automatedPaymentsScore = roundNum(automatedPaymentsScore, 2);
    console.log(`automated loan payments: `, automatedPaymentsScore)
    
    // 4. average mobile money transactions per month
    let averageMomoPMRaw = averageMomoPerMonth[numTransPerMonth];
    let averageMomoPMScore = (averageMomoPMRaw / averageMomoDenom) * averageMomoMaxWgt;
    averageMomoPMScore = roundNum(averageMomoPMScore, 2);
    console.log(`average mobile money transactions per month: ${averageMomoPMScore}`);

    // 5. minimun amount per transaction in a month
    let minAmtPMRaw = minAmtPerMonth[minAmtPM];
    let minAmtPMScore = (minAmtPMRaw / minAmtPerMonthDenom) * minAmtMaxWgt;
    minAmtPMScore = roundNum(minAmtPMScore, 2);
    console.log(`minimun amount per transaction in a month: ${minAmtPMScore}`);

    // 6. income source consistency
    let incomeConsistRaw = incomeConsist[incConsist]
    let incomeConsistScore = (incomeConsistRaw / incomeConsistDenom) * incomeConsistMaxWgt;
    incomeConsistScore = roundNum(incomeConsistScore, 2);
    console.log(`income source consistency: ${incomeConsistScore}`);

    // 7. number of years at work / current location of business 
    let yearsAtWorkRaw = yearsAtWork;
    let yearsAtWorkScore = (yearsAtWorkRaw/ workYearsDenom) * workYearsMaxWgt;
    yearsAtWorkScore = roundNum(yearsAtWorkScore, 2);
    console.log(`number of years at work: ${yearsAtWorkScore}`);

    // 8. recent 3 months loan payments
    let loanPaymentRaw = loanPaymentHstObj[loanPaymentHistory];
    let loanPaymentScore = (loanPaymentRaw / loanPaymentMax) * loanPaymentMaxWgt;
    loanPaymentScore = roundNum(loanPaymentScore, 2);
    console.log(`recent 3 months loan payments: ${loanPaymentScore}`);

    // 9. recent 3 months bills payments
    let billsPaymentRaw = billsPaymentHstObj[billPaymentHistory];
    let billsPaymentScore = (billsPaymentRaw / billsPaymentMax) * billsPaymentMaxWgt;
    billsPaymentScore = roundNum(billsPaymentScore, 2);
    console.log(`recent 3 months bills payments: ${billsPaymentScore}`);

    // 10. credit status for loan payment history
    let loanHistoryRaw = cedisCreditStatus[loanHistoryStatus];
    let loanHistoryStatusScore = (loanHistoryRaw / loanCreditStatusMax) * loanCreditStatusMaxWgt;
    loanHistoryStatusScore = roundNum(loanHistoryStatusScore, 2);
    console.log(`credit status for loan payment history: ${loanHistoryStatusScore}`);

    // 11. credit status for bills payment history 
    let billsHistoryStatusRaw = cedisCreditStatus[billsHistoryStatus];
    let billsHistoryStatusScore = (billsHistoryStatusRaw / billsCreditStatusMax) * billsCreditStatusMaxWgt;
    billsHistoryStatusScore = roundNum(billsHistoryStatusScore, 2);
    console.log(`credit status for loan payment history: ${billsHistoryStatusScore}`);
    
    //  totalScore
    let customerScore = scoringDTIScore + averageMomoBalanceScore + automatedPaymentsScore + averageMomoPMScore + minAmtPMScore + incomeConsistScore + yearsAtWorkScore + loanPaymentScore + billsPaymentScore + loanHistoryStatusScore + billsHistoryStatusScore;
    
    customerScore = roundNum(customerScore, 1);
    console.log("total score: ", customerScore)

    return {
        score : customerScore,
    }
}

export { 
    getCedisCreditScore 
}