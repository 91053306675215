function FormTextarea({ label, name, id, rows, placeholder, disabled, value, setValue, required }) {

    return (
        <div>
            <label htmlFor={name} className='form-label'>
                {label}
            </label>
            <textarea 
                name={name}
                id={id}
                rows={rows}
                className="form-textarea disabled:opacity-75 disabled:bg-gray-200 dark:placeholder:text-gray-700 disabled:cursor-not-allowed disabled:text-gray-700"
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                required={required}
                onChange={e => setValue(e.target.value)}
            ></textarea>
        </div>
    )
}

export default FormTextarea