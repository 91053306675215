import { useState, useEffect } from 'react'
import axios from 'axios'

function useLocation() {

    const [locationData, setLocationData] = useState(null);

    const getLocation = async () => {
        const res = await axios.get("http://ip-api.com/json");
        if(res.status === 200) setLocationData(res?.data);
    }

    useEffect(() => {
        getLocation();
    }, []);

    return {
        city: locationData?.city,
        country: locationData?.country,
        countryCode: locationData?.countryCode,
        lat: locationData?.lat,
        lon: locationData?.lon,
        region: locationData?.regionName,
        regionCode: locationData?.region,
        timezone: locationData?.timezone,
        zip: locationData?.zip
    }
}

export default useLocation