// import { Dialog, Transition } from '@headlessui/react'
// import { Fragment, useState } from 'react'
import { Topbar, Aside, Main, PageHeader, ListLoans, Loader } from 'components';
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Link } from 'react-router-dom';
import { useUser, useLoans } from 'hooks';
import { validateGhanaCard } from 'components/misc/functions';

function Index() {
    const { user } = useUser();
    const { isLoading, loans } = useLoans();

    // const isOpen = useState(true);
    
    if(isLoading){
        return <Loader />
    }

    return (
        <main>
            <Topbar />
            
            <div className='pt-16 w-full flex overflow-hidden bg-light-bg dark:bg-dark-page'>
                <Aside />

                <Main>
                    {/* show alert if account not verified or show grid of cards */}
                    {/* {(user?.isVerified && user?.idNumber !== undefined) || (user?.idNumber !== undefined && user?.idNumber !== "") ? ( */}
                    {(user?.isVerified && validateGhanaCard(user?.idNumber)) ? (
                        <>
                        <div className="flex items-center justify-between">
                            <PageHeader title='Dashboard' size="text-2xl" />

                            <Link to="/loans" className='text-sm text-gold-500 hover:text-gold-700 mr-4'>
                                Apply Now!
                            </Link>
                        </div>

                        <ListLoans loans={loans} />
                        </>
                    ) : (
                        <>
                        <div className='display_card'>
                            <div className='bg-red-100 text-red-700 px-4 py-3 rounded relative flex flex-row items-center' role='alert'>
                                <ExclamationTriangleIcon 
                                    className='h-5 w-5 text-red-700 mr-2'
                                />
                                <span className='block sm:inline'>
                                    <strong className='font-bold'>Account not verified!</strong> Please verify your account with <span className='font-bold'>Next of Kin Information</span> and <span className='font-bold'>GhanaCard Information</span> to continue. <Link to="/profile/edit" className='text-light-blue'>Go to Profile</Link>
                                </span>
                            </div>
                        </div>

                        {/* <Transition appear show={isOpen} as={Fragment}>
                            <Dialog as="div" className="relative z-50" onClose={() => {}}>
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <div className="fixed inset-0 bg-black/25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                                            </div>
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title as="h3" className="text-base font-semibold text-gray-900">
                                                    Account not verified!
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        Please verify your account with <span className='font-bold'>Next of Kin Information</span> and <span className='font-bold'>GhanaCard Information</span> to continue.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <Link
                                            to="/profile/edit"
                                            role='button'
                                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        >
                                            Go to Profile
                                        </Link>
                                    </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                                </div>
                            </div>
                            </Dialog>
                        </Transition> */}
                        </>
                    )}
                    {/* show alert if account not verified or show grid of cards */}
                </Main>
            </div>
        </main>
    )
}

export default Index