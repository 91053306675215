import { roundNum } from "../constants";
import { workYears } from "../loanConstants";

// ----- CONSTANTS ----- //
// scoring dti 
const scoringDTIStatus = {
    below35 : 30,
    upTo43 : 20,
    upTo50 : 15,
    above51 : 5
}

// savings balance
const pensionIncomeRange = {
    above10k : 40,
    max10k : 30,
    max5k : 20,
    below2k : 10
}

// pension balance
const pensionBalObj = {
    above20k : 40,
    max20k : 30,
    max10k : 20,
    max5k : 10
}

const pensionContYrsObj = {
    above10 : 40,
    max10 : 30,
    max5 : 20,
    below1 : 10
}

const pensionContOneYrObj = {
    monthly : 40,
    quarterly : 30,
    biyearly : 20,
    occasional : 10,
    inconsistent : 5
}

const avgPensionContObj = {
    above1k : 40,
    max1k : 30,
    below500 : 20
}

// automated payments scoring details
const automatedPaymentsObj = {
    Yes : 30,
    No : 0
}

// recent 3 months loan payment history
const loanPaymentHstObj = {
    Yes : 30,
    No : 0
}

// recent 3 months bills payment history
const billsPaymentHstObj = {
    Yes : 30,
    No : 0
}

// credit status for loan and bills payment history
const creditStatusLoanBills = {
    noMiss : 30,
    max1 : 20,
    max2 : 5,
    missedMore : 0,
    noCred : 0
}

//  ---- SCORING CARDS AND WEIGHTS -----  //
// new scoring dti
const scoringDTIDenom = Math.max(...Object.values(scoringDTIStatus));
const scoringDTIMaxWgt = 8;

// new loanme income values
const incomeFlowMax = Math.max(...Object.values(pensionIncomeRange));
const loanmeIncomeMaxWeight = 15;

// pension balance
const pensionBalDenom = Math.max(...Object.values(pensionBalObj));
const pensionBalMaxWgt = 10;

// pension contribution history (in years)
const pensionContYrsDenom = Math.max(...Object.values(pensionContYrsObj));
const pensionContYrsMaxWgt = 7;

// pension contribution history (within one year)
const pensionContOneYrDenom = Math.max(...Object.values(pensionContOneYrObj));
const pensionContOneYrMaxWgt = 10;

// average pension contribution per month
const avgPensionContDenom = Math.max(...Object.values(avgPensionContObj));
const avgPensionContMaxWgt = 10;

// years with current employerconst 
const workYearsDenom = Math.max(...Object.values(workYears))
const workYearsMaxWgt = 5;

// automated payments scoring details
const automatedDenom = Math.max(...Object.values(automatedPaymentsObj));
const automatedPaymentsMaxWgt = 8;

// recent 3 months loan payment history
const loanPaymentHstDenom = Math.max(...Object.values(loanPaymentHstObj));
const loanPaymentMaxWgt = 10;

// credit status loan payment history
const creditStatusLoanBillsDenom = Math.max(...Object.values(creditStatusLoanBills))
const creditStatusLoanPaymentMaxWgt = 7;

// recent 3 months bills payment history
const billsPaymentHstDenom = Math.max(...Object.values(billsPaymentHstObj));
const billsPaymentMaxWgt = 5;

// credit status bills payment history
const creditStatusBillsPaymentMaxWgt = 5;


// get the total score given inputs 
async function getPensionScore(scoringDTI, averageCashFlow, pensionBal, pensionContYrs, pensionContOneYr, avgPensionCont, automatedPaymentsStatus, yearsAtWork, loanPaymentHistory, defaultedLoanStatus, billPaymentHistory, billsHistoryStatus) {

    // 1. Debt to income ratio
    let scoringDTIRaw = scoringDTI <= 35 ? scoringDTIStatus.below35 : 
                        scoringDTI >= 36 && scoringDTI <= 43 ? scoringDTIStatus.upTo43 : 
                        scoringDTI >= 44 && scoringDTI <= 50 ? scoringDTIStatus.upTo50 : 
                        scoringDTIStatus.above51;
    let scoringDTIScore = (scoringDTIRaw / scoringDTIDenom) * scoringDTIMaxWgt;
    scoringDTIScore = roundNum(scoringDTIScore, 2);

    // 2. Income  
    let normIncomeScore = (averageCashFlow/ incomeFlowMax)*loanmeIncomeMaxWeight;
    normIncomeScore = roundNum(normIncomeScore, 2);

    // 3. Pension Balance
    let pensionBalRaw = pensionBalObj[pensionBal];
    let pensionBalScore = (pensionBalRaw / pensionBalDenom) * pensionBalMaxWgt;
    pensionBalScore = roundNum(pensionBalScore, 2);

    // 4. Pension Contribution history (in years)
    let pensionContYrsRaw = pensionContYrsObj[pensionContYrs];
    let pensionContYrsScore = (pensionContYrsRaw / pensionContYrsDenom) * pensionContYrsMaxWgt;
    pensionContYrsScore = roundNum(pensionContYrsScore, 2);

    // 5. Pension Contribution history (within 1 year)
    let pensionContOneYrRaw = pensionContOneYrObj[pensionContOneYr];
    let pensionContOneYrScore = (pensionContOneYrRaw / pensionContOneYrDenom) * pensionContOneYrMaxWgt;
    pensionContOneYrScore = roundNum(pensionContOneYrScore, 2);

    // 6. Average Pension Contribution per month
    let avgPensionContRaw = avgPensionContObj[avgPensionCont];
    let avgPensionContScore = (avgPensionContRaw / avgPensionContDenom) * avgPensionContMaxWgt;
    avgPensionContScore = roundNum(avgPensionContScore, 2)

    // 7. Automated payments 
    let automatedRaw = automatedPaymentsObj[automatedPaymentsStatus];
    let automatedScore = (automatedRaw / automatedDenom) * automatedPaymentsMaxWgt;
    automatedScore = roundNum(automatedScore, 2);

    // 8. Years with current employer
    let normAgeScoreRaw = yearsAtWork;
    let normAgeScore = (normAgeScoreRaw/workYearsDenom) * workYearsMaxWgt;
    normAgeScore = roundNum(normAgeScore, 2);

    // 9. Recent 3 months loan payment history 
    let freqIncomeScore = loanPaymentHstObj[loanPaymentHistory];
    let normFreqIncomeScore = (freqIncomeScore/loanPaymentHstDenom) * loanPaymentMaxWgt;
    normFreqIncomeScore = roundNum(normFreqIncomeScore, 2);
    
    // 10. Credit status of loan payment history 
    let DIRscoreRaw = creditStatusLoanBills[defaultedLoanStatus];
    let DIRscore = (DIRscoreRaw / creditStatusLoanBillsDenom) * creditStatusLoanPaymentMaxWgt;
    DIRscore = roundNum(DIRscore, 2);

    // 11. Recent 3 months bills payment history
    let billsHistoryRaw = billsPaymentHstObj[billPaymentHistory];
    let billsHistoryScore = (billsHistoryRaw / billsPaymentHstDenom) * billsPaymentMaxWgt;
    billsHistoryScore = roundNum(billsHistoryScore, 2)
    
    // 12. Credit status of bills payment history
    let billsHistoryStatusRaw = creditStatusLoanBills[billsHistoryStatus];
    let billsHistoryStatusScore = (billsHistoryStatusRaw / creditStatusLoanBillsDenom) * creditStatusBillsPaymentMaxWgt;
    billsHistoryStatusScore = roundNum(billsHistoryStatusScore, 2);


    console.log(`Scores. DTI: ${scoringDTIScore}, income: ${normIncomeScore}, pension balance: ${pensionBalScore}, pension contribution history (in years): ${pensionContYrsScore}, pension contribution history (within 1 year): ${pensionContOneYrScore}, average pension contribution per month: ${avgPensionContScore}, years with employer: ${normAgeScore}, automated payments: ${automatedScore}, 3 months loan payment history: ${normFreqIncomeScore}, credit status loan payments: ${DIRscore}, 3 months bills payment history: ${billsHistoryScore}, credit status bills payments: ${billsHistoryStatusScore}`);

    let totalScore = scoringDTIScore + normIncomeScore + pensionBalScore + pensionContYrsScore + pensionContOneYrScore + avgPensionContScore + normAgeScore + automatedScore + normFreqIncomeScore + DIRscore + billsHistoryScore + billsHistoryStatusScore;

    totalScore = roundNum(totalScore, 2);

    return {
        score: totalScore
    }
}

export { 
    getPensionScore
}