import React from 'react'
import { FaciLetter, File } from "components";

function Documents({ loan }) {

    return (
        <div>
            {loan?.adminStage === "pendingFacilityLetter" && loan?.state === "approved" && !loan?.facilityLetterUploaded && (
                <FaciLetter loan={loan} />
            )}

            <div className="px-0">
                <h3 className="text-lg font-semibold leading-7 text-gray-900 dark:text-white/[.8]">
                    Client Documents
                </h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-white/[.5]">
                    Other supporting documents uploaded.
                </p>
            </div>
            <div className="px-0 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white/[.8]">Attachments</dt>
                <dd className="mt-2 text-sm text-gray-900 dark:text-white/[.8] sm:col-span-2 sm:mt-0">
                    <ul className="divide-y divide-gray-100 dark:divide-white/[.2] rounded-md border border-gray-200 dark:border-white/[.2]">
                        {loan?.files && loan?.files.length > 0 ? (
                            loan?.files?.map((file, index) => (
                                <File key={index} item={file} id={loan?.loanId} />
                            ))
                        ) : (
                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <p>
                                    No attachments uploaded
                                </p>
                            </li>
                        )}
                    </ul>
                </dd>
            </div>
        </div>
    )
}

export default Documents